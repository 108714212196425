import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { ParseService } from '../../../../scan-data/parse-service/parse-service.service';

@Component({
  selector: 'app-new-cart-item-page',
  templateUrl: './new-cart-item-page.component.html',
  styleUrls: ['./new-cart-item-page.component.scss']
})
export class NewCartItemPageComponent implements OnInit {
  form: FormGroup;
  loading = false;
  shoppingProducts: Promise<Parse.Attributes[]>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private parseService: ParseService,
    private formBuilder: FormBuilder
  ) { }

  async ngOnInit(): Promise<void> {
    this.form = this.formBuilder.group({
      shoppingProduct: ['', [Validators.required]],
      quantity: ['', [Validators.required]],
    });

    this.shoppingProducts = this.parseService.getShoppingProductTranslations(await this.getShoppingId());
  }

  get shoppingProduct(): AbstractControl {
    return this.form.get('shoppingProduct');
  }

  get quantity(): AbstractControl {
    return this.form.get('quantity');
  }

  displayShoppingProductTranslation(shoppingProduct: Parse.Attributes): string {
    if (shoppingProduct) { return shoppingProduct.get('productTranslation').get('title'); }
  }

  async getShoppingId(): Promise<string> {
    const cartId = this.route.snapshot.paramMap.get('id');
    const cart = await this.parseService.getCart(cartId);
    const shopping = cart.attributes.shopping;
    return shopping.id;
  }

  async onSubmit(): Promise<void> {
    const shoppingProductId = this.shoppingProduct.value.id;
    const quantity = this.quantity.value;
    const cartId = this.route.snapshot.paramMap.get('id');
    if (!shoppingProductId || !quantity || !cartId) { return; }

    this.loading = true;
    await this.parseService.createCartItem(cartId, quantity, shoppingProductId);
    this.loading = false;

    this.router.navigate(['../'], { relativeTo: this.route });
  }
}
