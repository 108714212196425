<div fxLayout="row" fxLayoutAlign="center">
  <h1>New shopping product</h1>
</div>
<div fxLayout="row" class="actions">
  <span fxLayoutAlign="start center">
    <a class="link" [routerLink]="['../../../']">Shoppings</a>
    <span>&nbsp;&nbsp;/&nbsp;&nbsp;</span>
    <a class="link" [routerLink]="['../../']">Shopping products</a>
  </span>
  <span class="fill-space"></span>
  <button mat-raised-button color="accent" [routerLink]="['../barcode']">
    Find product from barcode
  </button>
  <span>&nbsp;&nbsp;</span>
  <button mat-raised-button color="accent" [routerLink]="['../../']">
    Back
  </button>
</div>
<div fxLayout="row" fxLayoutAlign="center">
  <mat-card>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <mat-form-field>
        <input
          matInput
          formControlName="query"
          (input)="onInputQuery()"
          placeholder="Search for specific product, category or type random string query..."
          autocomplete="off"
          type="text"
          [matAutocomplete]="auto"
        />
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayTranslation">
          <mat-option *ngFor="let translation of translations | async" [value]="translation">
            <!-- SCORE: {{ translation?.get('score') }} -->
            <ng-container *ngIf="utils.getProductImageUrl(translation) || utils.getProductImageUrl(translation?.get('product')) as productImageUrl">
              <img [src]="productImageUrl" class="preview-image" />
            </ng-container>
            <span>
              <small>{{translation?.className == 'CategoryTranslation' ? 'Category:' : 'Product:'}} </small>
              {{ translation?.get('title') }}
            </span>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <div fxLayoutAlign="center">
        <button
          mat-raised-button
          color="accent"
          type="submit"
          [disabled]="form.invalid || loading"
          fxLayoutAlign="center"
        >
          Add to shopping list
        </button>
      </div>
    </form>
  </mat-card>
</div>
