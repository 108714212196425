import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { Observable } from 'rxjs';
import { switchMap, debounceTime, tap, finalize } from 'rxjs/operators';
import { ParseService } from '../../../../scan-data/parse-service/parse-service.service';
import { ShoppingUtils } from '../shopping-utils';

@Component({
  selector: 'app-new-shopping-product-page',
  templateUrl: './new-shopping-product-page.component.html',
  styleUrls: ['./new-shopping-product-page.component.scss']
})
export class NewShoppingProductPageComponent implements OnInit {
  form: FormGroup;
  loading = false;
  translations: Observable<Parse.Attributes[]>;
  // NOTE: user's literal string value of query input (typed or pasted) but not selected from the autocomplete list
  literalQuery: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private parseService: ParseService,
    private formBuilder: FormBuilder,
    public utils: ShoppingUtils
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      query: ['', [Validators.required]],
    });

    this.translations = this.query.valueChanges
      .pipe(
        debounceTime(300),
        switchMap(value => this.parseService.getTranslationsByQuery(value, 'hr'))
      );
  }

  get query(): AbstractControl {
    return this.form.get('query');
  }

  displayTranslation(translation: Parse.Attributes): string {
    if (translation) { return translation.get('title'); }
  }

  async onSubmit(): Promise<void> {
    const value = this.query.value;
    const shoppingId = this.route.snapshot.paramMap.get('id');
    if (!value || !shoppingId) { return; }

    this.loading = true;
    await this.parseService.createShoppingProductAndSearch(shoppingId, value, this.literalQuery);
    this.loading = false;

    this.router.navigate(['../../'], { relativeTo: this.route });
  }

  /**
   * Take the literal user's input for search query
   */
  public onInputQuery(): void {
    const value = this.query.value;
    this.literalQuery = value;
  }

}
