import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

import { ParseService } from '../../../../scan-data/parse-service/parse-service.service';

@Component({
  selector: 'app-list-page',
  templateUrl: './list-page.component.html',
  styleUrls: ['./list-page.component.scss']
})
export class ListPageComponent implements OnInit {

  displayedColumns: string[] = ['id', 'title', 'createdAt', 'actions'];
  dataSource: MatTableDataSource<Parse.Object<Parse.Attributes>>;
  shoppings: Parse.Object[];
  isLoading = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(private parseService: ParseService) {
  }

  async ngOnInit(): Promise<void> {
    this.dataSource = new MatTableDataSource<Parse.Object<Parse.Attributes>>();
    this.dataSource.paginator = this.paginator;

    this.pageEvent({ pageIndex: 0, pageSize: 5, length: 0 });
  }

  async pageEvent(pageEvent: PageEvent): Promise<void> {
    this.isLoading = true;
    this.dataSource.data = [];
    const response = await this.parseService.getShoppings(Parse.User.current().id, pageEvent.pageIndex, pageEvent.pageSize);
    this.shoppings = response.results;
    this.paginator.length = response.count;
    this.dataSource = new MatTableDataSource<Parse.Object<Parse.Attributes>>(this.shoppings);
    this.isLoading = false;
  }

}
