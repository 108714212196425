import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LayoutModule } from '@angular/cdk/layout';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatExpansionModule } from '@angular/material/expansion';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyModule } from '@ngx-formly/core';
import { RouterModule } from '@angular/router';

import { ShellComponent } from './shell/shell.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { FormlyChipsComponent } from './formly-chips/formly-chips.component';
import { RepeatTypeComponent } from './formly-repeat/repeat-section.type';

import { DropZoneDirective } from '../directives/drop-zone.directive';
import { MatDialogModule } from '@angular/material/dialog';

const components = [ShellComponent, FileUploadComponent, FormlyChipsComponent, RepeatTypeComponent];

const declarations = [DropZoneDirective];

const modules = [
  CommonModule,
  LayoutModule,
  FlexLayoutModule,
  ReactiveFormsModule,
  MatAutocompleteModule,
  MatButtonModule,
  MatCardModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatSidenavModule,
  MatSnackBarModule,
  MatTableModule,
  MatToolbarModule,
  MatChipsModule,
  MatAutocompleteModule,
  MatSelectModule,
  MatExpansionModule,
  MatDialogModule,
  RouterModule,
  FormlyMaterialModule,
];

@NgModule({
  declarations: [...components, ...declarations],
  imports: [...modules,
  FormlyModule.forRoot({
    types: [{
      name: 'chips',
      wrappers: ['form-field'],
      component: FormlyChipsComponent,
      defaultOptions: {
        defaultValue: [],
      },
    },
    { name: 'repeat', component: RepeatTypeComponent },
    ],
  })
  ],
  exports: [...components, ...declarations, ...modules, FormlyModule]
})
export class SharedModule { }
