import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';

import { ParseService } from '../../../../scan-data/parse-service/parse-service.service';
import { ShoppingUtils } from '../shopping-utils';

@Component({
  selector: 'app-details-page',
  templateUrl: './details-page.component.html',
  styleUrls: ['./details-page.component.scss']
})
export class DetailsPageComponent implements OnInit, OnDestroy {

  shoppingId: string;
  displayedColumns: string[] = ['id', 'image', 'item', 'quantity', 'createdAt'];
  shoppingProducts: Parse.Object[];
  dataSource: MatTableDataSource<Parse.Object<Parse.Attributes>>;
  subscription: Parse.LiveQuerySubscription;

  constructor(
    private route: ActivatedRoute,
    private parseService: ParseService,
    public utils: ShoppingUtils
  ) { }

  async ngOnInit(): Promise<void> {
    this.shoppingId = this.route.snapshot.paramMap.get('id');
    this.shoppingProducts = await this.parseService.getShoppingProducts(this.shoppingId);
    this.dataSource = new MatTableDataSource<Parse.Object<Parse.Attributes>>(this.shoppingProducts);

    this.subscription = await this.parseService.getShoppingProductsSubscription(this.shoppingId);

    const onCreate = (shoppingProduct) => {
      this.shoppingProducts.unshift(shoppingProduct);
      this.dataSource.data = this.shoppingProducts;
    };
    const onUpdate = (shoppingProduct) => {
      const foundIndex = this.shoppingProducts.findIndex(x => x.id === shoppingProduct.id);
      this.shoppingProducts[foundIndex] = shoppingProduct;
      this.dataSource.data = this.shoppingProducts;
    };
    const onDelete = (shoppingProduct) => {
      const foundIndex = this.shoppingProducts.findIndex(x => x.id === shoppingProduct.id);
      this.shoppingProducts.splice(foundIndex, 1);
      this.dataSource.data = this.shoppingProducts;
    };
    this.subscription.on('create', onCreate);
    this.subscription.on('enter', onCreate);
    this.subscription.on('update', onUpdate);
    this.subscription.on('delete', onDelete);
    this.subscription.on('leave', onDelete);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
