import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListPageComponent } from './list-page/list-page.component';
import { NewShoppingPageComponent } from './new-shopping-page/new-shopping-page.component';
import { DetailsPageComponent } from './details-page/details-page.component';
import { NewShoppingProductPageComponent } from './new-shopping-product-page/new-shopping-product-page.component';
import { NewShoppingProductBarcodeComponent } from './new-shopping-product-barcode/new-shopping-product-barcode.component';
import { CartsComponent } from './carts/carts.component';
import { NewCartPageComponent } from './new-cart-page/new-cart-page.component';
import { CartItemsPageComponent } from './cart-items-page/cart-items-page.component';
import { NewCartItemPageComponent } from './new-cart-item-page/new-cart-item-page.component';


const routes: Routes = [
  { path: '', component: ListPageComponent },
  { path: 'new', component: NewShoppingPageComponent },
  { path: ':id', component: DetailsPageComponent },
  { path: ':id/product/new', component: NewShoppingProductPageComponent },
  { path: ':id/product/barcode', component: NewShoppingProductBarcodeComponent },
  { path: ':id/cart', component: CartsComponent },
  { path: ':id/cart/new', component: NewCartPageComponent },
  { path: ':id/cart/:id', component: CartItemsPageComponent },
  { path: ':id/cart/:id/new', component: NewCartItemPageComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ShoppingRoutingModule { }
