import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';

import { ParseService } from '../../../../scan-data/parse-service/parse-service.service';
import { MatDialog } from '@angular/material/dialog';
import { FinalizeDialogComponent } from './finalize-dialog/finalize-dialog.component';
import { CorrectionDialogComponent } from './correction-dialog/correction-dialog.component';
import { ShoppingUtils } from '../../shopping/shopping-utils';
import { LockCorrectionDialogComponent } from './lock-correction-dialog/lock-correction-dialog.component';

@Component({
  selector: 'app-scanshop-details-page',
  templateUrl: './details-page.component.html',
  styleUrls: ['./details-page.component.scss']
})
export class DetailsPageComponent implements OnInit {

  receiptId: string;

  receiptVersionsDisplayedColumns: string[] = ['id', 'createdAt', 'version', 'retailer', 'actions'];
  receiptVersionsDataSource: MatTableDataSource<Parse.Object<Parse.Attributes>>;

  receiptItemsDisplayedColumns: string[] = ['id', 'title', 'title-correction', 'unitPrice', 'quantity', 'totalPrice',
    'product', 'product-correction', 'category', 'category-correction', 'actions'];
  receiptItemsAndCorrectionsDataSource: MatTableDataSource<Parse.Object<Parse.Attributes>>;

  receipt: Parse.Object<Parse.Attributes>;
  receiptReceiptScanVersions: Parse.Object[];
  retailerImage: Parse.Object<Parse.Attributes>;

  isFinalizingInProgress: boolean
  isLockingCorrectionInProgress: boolean

  constructor(private route: ActivatedRoute, private parseService: ParseService, public dialog: MatDialog, public utils: ShoppingUtils) { }

  async ngOnInit(): Promise<void> {
    this.receiptId = this.route.snapshot.paramMap.get('id');
    this.receiptReceiptScanVersions = await this.parseService.getReceiptScanVersions(this.receiptId);
    this.receiptVersionsDataSource = new MatTableDataSource<Parse.Object<Parse.Attributes>>(this.receiptReceiptScanVersions);

    this.receipt = await this.parseService.getReceipt(this.receiptId);
    if (this.receipt.get('retailerDetectedFromImage')) {
      this.retailerImage = await this.parseService.getReceiptScanVersionImage(this.receipt.get('retailerDetectedFromImage').id);
    }

    if (this.receipt.get('isFinalized')) {
      await this.displayReceiptScanItems();
    }

  }

  async displayReceiptScanItems(): Promise<void> {
    if (this.receiptReceiptScanVersions.length > 0) {

      // NOTE(stepanic): removed taking the latest ReceiptScanVersion because it could be finalized from some other scan version but at cloud code is
      // setting the latest one
      // const latestScanVersion = this.receiptReceiptScanVersions.reduce((prev, current) => {
      //   return (prev.get('createdAt') > current.get('createdAt')) ? prev : current;
      // });
      // const receiptItemsAndCorrections = await this.parseService.getReceiptScanItemsAndCorrections(latestScanVersion);

      // NOTE(stepanic): for existing objects parse-server does not return new created objects in the afterSave
      // and it is required to fetch the receipt object againg because property `finalizedFromReceiptScanVersion` is missing
      if (!this.receipt.get('finalizedFromReceiptScanVersion')) {
        this.receipt = await this.parseService.getReceipt(this.receiptId);
      }
      const receiptItemsAndCorrections = await this.parseService.getReceiptScanItemsAndCorrections(this.receipt.get('finalizedFromReceiptScanVersion'));
      this.receiptItemsAndCorrectionsDataSource = new MatTableDataSource<Parse.Object<Parse.Attributes>>(receiptItemsAndCorrections);
    }
  }

  openFinalizeDialog(): void {
    const dialogRef = this.dialog.open(FinalizeDialogComponent);

    dialogRef.afterClosed().subscribe(async result => {
      if (result === true) {
        // NOTE(stepanic): for existing objects parse-server does not return new created objects in the afterSave
        this.isFinalizingInProgress = true
        this.receipt = await this.parseService.finalizeReceipt(this.receipt);
        await this.displayReceiptScanItems();
        this.isFinalizingInProgress = false
      }
    });
  }

  openLockCorrectionDialog(): void {
    const dialogRef = this.dialog.open(LockCorrectionDialogComponent);

    dialogRef.afterClosed().subscribe(async result => {
      if (result === true) {
        // console.log('Receipt.LockCorrection.started')
        this.isLockingCorrectionInProgress = true
        await this.parseService.lockReceiptCorrection(this.receipt);
        this.isLockingCorrectionInProgress = false
        // console.log('Receipt.LockCorrection.finished')
      }
    });
  }

  openCorrectionDialog(item: Parse.Object): void {
    const dialogRef = this.dialog.open(CorrectionDialogComponent, {
      width: '500px',
      data: { item }
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result && result.form) {

        const query = result.form.query
        let productOrCategory = null
        if (query.get) {
          if (query?.get('product')) {
            productOrCategory = query?.get('product')
          } else if (query?.get('category')) {
            productOrCategory = query?.get('category')
          }
        }

        await this.parseService.createOrUpdateReceiptScanItemCorrection(
          result.item,
          productOrCategory,
          result.form.title
        );
      }
    });
  }

}
