<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #drawer
    class="sidenav"
    fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navgation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="!(isHandset$ | async)"
  >
    <mat-toolbar>
      🗃️ Pages
      <mat-icon
        aria-label="Side nav close icon"
        class="sidenav-close-icon"
        (click)="drawer.close()"
      >
        close
      </mat-icon>
    </mat-toolbar>
    <mat-nav-list>
      <a mat-list-item routerLink="/" (click)="isHandset && drawer.close()">🏡 Home</a>
      <br>
      <a mat-list-item routerLink="/retailer" (click)="isHandset && drawer.close()">🏪 Retailers</a>
      <br>
      <a mat-list-item routerLink="/receipt" (click)="isHandset && drawer.close()">🧾 Receipts</a>
      <br>
      <a mat-list-item routerLink="/shopping" (click)="isHandset && drawer.close()">🛒 Shoppings</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar>
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="!drawer.opened"
      >
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span class="logo" routerLink="/">🔆 ScanShop</span>
      <span class="fill-space"></span>
      <a *ngIf="!currentUser" mat-button routerLink="/login">🔑 Login</a>
      <a *ngIf="!!currentUser" mat-button routerLink="/login">👤 Profile</a>
    </mat-toolbar>
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
