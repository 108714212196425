import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ParseService } from '../../../../scan-data/parse-service/parse-service.service';

@Component({
  selector: 'app-new-receipt-page',
  templateUrl: './new-receipt-page.component.html',
  styleUrls: ['./new-receipt-page.component.scss']
})
export class NewReceiptPageComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute, private parseService: ParseService) { }

  ngOnInit(): void {
  }

  async startUpload(file: File) {
    await this.parseService.createReceipt(file);
    this.router.navigate(['../'], { relativeTo: this.route });
  }

}
