import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { Observable } from 'rxjs';
import { ParseService } from '../../../../scan-data/parse-service/parse-service.service';

@Component({
  selector: 'app-new-shopping-page',
  templateUrl: './new-shopping-page.component.html',
  styleUrls: ['./new-shopping-page.component.scss']
})
export class NewShoppingPageComponent implements OnInit {
  form: FormGroup;
  loading = false;
  translations: Observable<Parse.Attributes[]>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private parseService: ParseService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      title: ['', [Validators.required]],
    });
  }

  get title(): AbstractControl {
    return this.form.get('title');
  }

  async onSubmit(): Promise<void> {
    const value = this.title.value;
    if (!value) { return; }

    this.loading = true;
    await this.parseService.createShopping(value);
    this.loading = false;

    this.router.navigate(['../'], { relativeTo: this.route });
  }

}
