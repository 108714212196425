<div fxLayout="row" fxLayoutAlign="center">
  <h1>Receipt version images</h1>
</div>
<div fxLayout="row" class="actions">
  <span fxLayoutAlign="start center">
    <a class="link" [routerLink]="['../../../']">Receipts</a>
    <span>&nbsp;&nbsp;/&nbsp;&nbsp;</span>
    <a class="link" [routerLink]="['../../']">Versions</a>
  </span>
  <span class="fill-space"></span>
  <button mat-raised-button color="accent" routerLink="./image/new">
    New
  </button>
</div>
<div fxLayout="row" fxLayoutAlign="center">
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>Id</th>
      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
    </ng-container>
    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef>Created At</th>
      <td mat-cell *matCellDef="let element">{{ element.createdAt.toLocaleString("hr-HR") }}</td>
    </ng-container>
    <ng-container matColumnDef="retailer">
      <th mat-header-cell *matHeaderCellDef>Retailer</th>
      <td mat-cell *matCellDef="let element">
        {{
          element.id === retailerImageId
            ? this.receipt.get('retailer').get('code')
            : ''
        }}
      </td>
    </ng-container>
    <ng-container matColumnDef="image">
      <th mat-header-cell *matHeaderCellDef>Image</th>
      <td mat-cell *matCellDef="let element">
        <a target="_blank" class="link" [href]="element.get('image').url()">
          {{ element.get('image').name() }}
        </a>
      </td>
    </ng-container>
    <ng-container matColumnDef="preview">
      <th mat-header-cell *matHeaderCellDef>Preview</th>
      <td mat-cell *matCellDef="let element">
        <img
          [src]="imgPrefix + element.get('image').url().split('parse/upload/').pop()"
          class="preview-image"
        />
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
