<h2 mat-dialog-title>Item correction</h2>
<form [formGroup]="form" (ngSubmit)="save()">
  <mat-dialog-content class="mat-typography">
      <mat-form-field>
        <input
          matInput
          formControlName="title"
          placeholder="Title (OCR) correction"
          autocomplete="off"
          type="text"
        />
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          formControlName="query"
          (input)="onInputQuery()"
          placeholder="Product or category correction"
          autocomplete="off"
          type="text"
          [matAutocomplete]="auto"
        />
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayTranslation">
          <mat-option *ngFor="let translation of translations | async" [value]="translation">
            <!-- SCORE: {{ translation?.get('score') }} -->
            <ng-container *ngIf="utils.getProductImageUrl(translation) || utils.getProductImageUrl(translation?.get('product')) as productImageUrl">
              <img [src]="productImageUrl" class="preview-image" />
            </ng-container>
            <span>
              <small>{{translation?.className === 'CategoryTranslation' ? 'Category:' : 'Product:'}} </small>
              {{ translation?.get('title') }}
            </span>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button type="button" (click)="close()">Cancel</button>
    <button mat-button type="submit" (click)="save()">Submit</button>
  </mat-dialog-actions>
</form>
