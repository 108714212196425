import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { Observable } from 'rxjs';
import { switchMap, debounceTime, tap, finalize } from 'rxjs/operators';
import { ParseService } from '../../../../scan-data/parse-service/parse-service.service';

@Component({
  selector: 'app-new-cart-page',
  templateUrl: './new-cart-page.component.html',
  styleUrls: ['./new-cart-page.component.scss']
})
export class NewCartPageComponent implements OnInit {
  form: FormGroup;
  loading = false;
  translations: Observable<Parse.Attributes[]>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private parseService: ParseService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      storeTranslation: ['', [Validators.required]],
      status: ['', [Validators.required]],
    });

    this.translations = this.storeTranslation.valueChanges
      .pipe(
        debounceTime(300),
        switchMap(value => this.parseService.getStoreTranslationsByQuery(value, 'hr'))
      );
  }

  get storeTranslation(): AbstractControl {
    return this.form.get('storeTranslation');
  }

  get status(): AbstractControl {
    return this.form.get('status');
  }

  displayTranslation(translation: Parse.Attributes): string {
    if (translation) { return translation.get('title'); }
  }

  async onSubmit(): Promise<void> {
    const storeTranslationId = this.storeTranslation.value?.id;
    const status = this.status.value;
    const shoppingId = this.route.snapshot.paramMap.get('id');
    if (!storeTranslationId || !status || !shoppingId) { return; }

    this.loading = true;
    await this.parseService.createCart(shoppingId, storeTranslationId, status);
    this.loading = false;

    this.router.navigate(['../'], { relativeTo: this.route });
  }

}
