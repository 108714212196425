<div fxLayout="column" fxLayoutAlign="center center">

  <div *ngIf="isUploading" class="upload-in-progress-message">
    <h3>Upload in progress...</h3>
    <p>Please wait for several seconds until all background jobs are done.</p>
  </div>

  <mat-progress-spinner *ngIf="isUploading"
                        mode="indeterminate" ></mat-progress-spinner>

  <div *ngIf="!isUploading"
    class="dropzone"
    dropZone
    (hovered)="toggleHover($event)"
    (dropped)="processFile($event)"
    [class.hovering]="isHovering"
    (click)="inputFileImage.click()"
  >
    <h3>Drag and Drop</h3>
    <h4>or click to choose a file…</h4>
    <input
      #inputFileImage
      class="file-input"
      type="file"
      accept="image/*"
      capture="camera"
      (change)="processFile($event.target.files)"
      [disabled]="isUploading"
    />
  </div>
</div>
