<div fxLayout="row" fxLayoutAlign="center">
  <h1>New shopping</h1>
</div>
<div fxLayout="row" class="actions">
  <span fxLayoutAlign="start center">
    <a class="link" [routerLink]="['../']">Shoppings</a>
  </span>
  <span class="fill-space"></span>
  <button mat-raised-button color="accent" [routerLink]="['../']">
    Back
  </button>
</div>
<div fxLayout="row" fxLayoutAlign="center">
  <mat-card>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <mat-form-field>
        <input
          matInput
          formControlName="title"
          placeholder="Title"
          type="text"
        />
      </mat-form-field>

      <div fxLayoutAlign="center">
        <button
          mat-raised-button
          color="accent"
          type="submit"
          [disabled]="form.invalid || loading"
          fxLayoutAlign="center"
        >
          Submit
        </button>
      </div>
    </form>
  </mat-card>
</div>
