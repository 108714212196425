import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from './shared/shared.module';
import { PagesModule } from './pages/pages.module';
import { UserModule } from './user/user.module';

const modules = [
  CommonModule,
  SharedModule,
  PagesModule,
  UserModule
];

@NgModule({
  declarations: [],
  imports: [...modules],
  exports: [...modules]
})
export class UiModule { }
