<div fxLayout="row" fxLayoutAlign="center">
  <h1>Retailer details</h1>
</div>
<div fxLayout="row" class="actions">
  <span fxLayoutAlign="start center">
    <a class="link" [routerLink]="['../']">Retailers</a>
  </span>
</div>
<span class="fill-space"></span>
<div fxLayout="row">
  <mat-card style="width: 100%;">
    <form [formGroup]="form" (ngSubmit)="submit()">
      <formly-form [model]="model" [fields]="fields" [options]="options" [form]="form"></formly-form>
      <button mat-button color="primary" type="submit" class="btn btn-primary submit-button" [disabled]="!form.valid">
        Submit
      </button>
    </form>
  </mat-card>
</div>
