<div fxLayout="row" fxLayoutAlign="center">
  <h1>Logout</h1>
</div>
<div fxLayout="row" fxLayoutAlign="center">
  <p>
    Logged in as <strong>{{ currentUsername }}</strong>
  </p>
</div>

<div fxLayout="row" fxLayoutAlign="center">
  <button mat-stroked-button (click)="onLogout()">Logout</button>
</div>
