import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListPageComponent } from './list-page/list-page.component';
import { DetailsPageComponent } from './details-page/details-page.component';
import { VersionPageComponent } from './version-page/version-page.component';
import { NewReceiptPageComponent } from './new-receipt-page/new-receipt-page.component';
import { NewVersionPageComponent } from './new-version-page/new-version-page.component';
import { NewImagePageComponent } from './new-image-page/new-image-page.component';


const routes: Routes = [
  { path: '', component: ListPageComponent },
  { path: 'new', component: NewReceiptPageComponent },
  { path: ':id', component: DetailsPageComponent },
  { path: ':id/version/new', component: NewVersionPageComponent },
  { path: ':id/version/:id', component: VersionPageComponent },
  { path: ':id/version/:id/image/new', component: NewImagePageComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReceiptRoutingModule { }
