import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './ui/pages/home-page/home-page.component';
import { AuthGuard } from './ui/user/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: HomePageComponent
  },
  {
    path: 'login',
    loadChildren: () => import('./ui/user/user.module').then(m => m.UserModule)
  },
  {
    path: 'receipt',
    loadChildren: () => import('./ui/pages/receipt/receipt.module').then(m => m.ReceiptModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'retailer',
    loadChildren: () => import('./ui/pages/retailer/retailer.module').then(m => m.RetailerModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'shopping',
    loadChildren: () => import('./ui/pages/shopping/shopping.module').then(m => m.ShoppingModule),
    canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
