import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
} from '@angular/forms';
import { ParseService } from '../../../../scan-data/parse-service/parse-service.service';
import Quagga from 'quagga';
import { ShoppingUtils } from '../shopping-utils';

@Component({
  selector: 'app-new-shopping-product-barcode',
  templateUrl: './new-shopping-product-barcode.component.html',
  styleUrls: ['./new-shopping-product-barcode.component.scss']
})
export class NewShoppingProductBarcodeComponent implements OnInit, OnDestroy {

  form: FormGroup;
  loading = false;
  productTranslationFetchInProgress = false;
  productTranslationIsFound = false;
  productTranslation: Parse.Attributes;
  productImage: string;
  quaggaStarted = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private parseService: ParseService,
    private formBuilder: FormBuilder,
    public utils: ShoppingUtils
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      barcode: [''],
    });

    this.form.get('barcode').valueChanges.subscribe(val => {
      this.barcodeChange();
    });

    if (navigator.mediaDevices && (typeof navigator.mediaDevices.getUserMedia === 'function')) {
      Quagga.init({
        inputStream: {
          constraints: {
            facingMode: 'environment',
          },
        },
        decoder: {
          readers: [
            'ean_reader',  // EAN-13
            'ean_8_reader' // EAN-8
          ],
        },
      },
      (err) => {
        if (err) {
          console.log(`QuaggaJS could not be initialized, err: ${err}`);
        } else {
          Quagga.start();
          this.quaggaStarted = true;
          Quagga.onDetected((res) => {
            this.onBarcodeScanned(res.codeResult.code);
          });
        }
      });
      Quagga.onProcessed(result => {
        const drawingCtx = Quagga.canvas.ctx.overlay;
        const drawingCanvas = Quagga.canvas.dom.overlay;

        if (result) {
          if (result.boxes) {
            drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute('width'), 10),
              parseInt(drawingCanvas.getAttribute('height'), 10));
            result.boxes.filter(box => {
              return box !== result.box;
            }).forEach(box => {
              Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, { color: '#00C3FF', lineWidth: 2 });
            });
          }

          if (result.box) {
            Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, { color: '#00C3FF', lineWidth: 2 });
          }

          if (result.codeResult && result.codeResult.code) {
            Quagga.ImageDebug.drawPath(result.line, { x: 'x', y: 'y' }, drawingCtx, { color: '#00A0FF', lineWidth: 3 });
          }
        }
      });
    }
  }

  ngOnDestroy(): void {
    if (this.quaggaStarted) {
      Quagga.stop();
    }
  }

  onBarcodeScanned(code: string): void {
    if (!this.productTranslation) {
      if (!this.productTranslationFetchInProgress) {
        this.form.get('barcode').setValue(code);
      }
    }
  }

  async barcodeChange(): Promise<void> {
    if (this.form.get('barcode').valid) {

      // On delete input restart barcode reader
      if (!this.form.get('barcode').value) {
        Quagga.start();
        this.productTranslationIsFound = false;
        this.productTranslationFetchInProgress = false;
        this.productTranslation = undefined;
        return;
      }

      this.productTranslationFetchInProgress = true;
      this.productTranslation = await this.parseService.getTranslationByBarcode(this.form.get('barcode').value, 'hr');
      this.productTranslationFetchInProgress = false;

      if (this.productTranslation) {
        Quagga.pause();
        this.productTranslationIsFound = true;
      }
    } else {
      this.productTranslation = undefined;
    }
  }

  async onSubmit(): Promise<void> {
    const shoppingId = this.route.snapshot.paramMap.get('id');
    if (!this.productTranslation || !shoppingId) { return; }

    this.loading = true;
    await this.parseService.createShoppingProductAndSearch(shoppingId, this.productTranslation, this.form.get('barcode').value);
    this.loading = false;

    // this.productTranslation

    this.router.navigate(['../../'], { relativeTo: this.route });
  }

}
