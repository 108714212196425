import * as Parse from 'parse';

export const Customer = Parse.Object.extend('Customer');
export const Language = Parse.Object.extend('Language');
export const Product = Parse.Object.extend('Product');
export const ProductTranslation = Parse.Object.extend('ProductTranslation');
export const Category = Parse.Object.extend('Category');
export const CategoryTranslation = Parse.Object.extend('CategoryTranslation');
export const Receipt = Parse.Object.extend('Receipt');
export const ReceiptType = Parse.Object.extend('ReceiptType');
export const ReceiptScanImage = Parse.Object.extend('ReceiptScanImage');
export const ReceiptScanVersion = Parse.Object.extend('ReceiptScanVersion');
export const ReceiptScanItem = Parse.Object.extend('ReceiptScanItem');
export const ReceiptScanItemCorrection = Parse.Object.extend('ReceiptScanItemCorrection');
export const User = Parse.Object.extend('User');
export const Retailer = Parse.Object.extend('Retailer');
export const Company = Parse.Object.extend('Company');
export const Country = Parse.Object.extend('Country');
export const Shopping = Parse.Object.extend('Shopping');
export const ShoppingProduct = Parse.Object.extend('ShoppingProduct');
export const ShoppingProductSearch = Parse.Object.extend('ShoppingProductSearch');
export const ProductBarcode = Parse.Object.extend('ProductBarcode');
export const ProductImage = Parse.Object.extend('ProductImage');
export const ProductAtStore = Parse.Object.extend('ProductAtStore');
export const StoreTranslation = Parse.Object.extend('StoreTranslation');
export const Cart = Parse.Object.extend('Cart');
export const CartItem = Parse.Object.extend('CartItem');
export const Store = Parse.Object.extend('Store');

