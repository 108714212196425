import { Component, OnInit, OnDestroy } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Component({
  selector: 'scanshop-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent implements OnInit, OnDestroy { 

  isHandset$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.Handset])
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  isHadsetSub: any;

  isHandset: boolean;

  constructor(private breakpointObserver: BreakpointObserver) {
    this.isHandset = false;
  }

  ngOnInit() {
    this.isHadsetSub = this.isHandset$.subscribe(observer => {
      this.isHandset = observer;
    })
  }

  ngOnDestroy(): void {
    this.isHadsetSub.unsubscribe();
  }

  get currentUser() {
    return Parse.User.current();
  }

}
