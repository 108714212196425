import { Component } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-repeat-section',
  template: `
    <div *ngFor="let field of field.fieldGroup; let i = index;" class="row">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ to.fieldTitle }} {{i+1}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <formly-field class="col" [field]="field"></formly-field>
        <mat-action-row>
          <button mat-button color="warn" type="button" (click)="remove(i)">{{ to.removeText }}</button>
        </mat-action-row>
      </mat-expansion-panel>
    </div>
    <button mat-button color="accent" type="button" (click)="add()" style="margin-top: 10px;">{{ to.addText }}</button>
    <div style="display: block; font-size: 0.8em; color: #f44336; padding: 15px;">
      <formly-validation-message [field]="field"></formly-validation-message>
    </div>
  `,
})
export class RepeatTypeComponent extends FieldArrayType {}
