import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

import { ParseService } from '../../../../scan-data/parse-service/parse-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-scanshop-list-page',
  templateUrl: './list-page.component.html',
  styleUrls: ['./list-page.component.scss']
})
export class ListPageComponent implements OnInit {

  displayedColumns: string[] = ['id', 'createdAt', 'retailer', 'actions', 'isFinalized', 'preview'];
  dataSource: MatTableDataSource<Parse.Object<Parse.Attributes>>;
  imgPrefix = 'https://ik.imagekit.io/scanshop/tr:w-200,h-200,c-at_max/';
  receipts: Parse.Object<Parse.Attributes>[];
  isLoading = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(private parseService: ParseService, private snackBar: MatSnackBar) {
  }

  async ngOnInit(): Promise<void> {
    this.dataSource = new MatTableDataSource<Parse.Object<Parse.Attributes>>();
    this.dataSource.paginator = this.paginator;

    this.pageEvent({ pageIndex: 0, pageSize: 5, length: 0 });
  }

  async refreshRetailerDetection(receiptId: string): Promise<void> {
    try {
      this.snackBar.open(`Retailer detection initialized for ${receiptId}`, 'Dismiss', { duration: 3000, });
      await this.parseService.refreshRetailerDetection(receiptId);
      this.receipts.find(receipt => receipt.id === receiptId)
        .set('retailer', (await this.parseService.getReceipt(receiptId)).get('retailer'));
    } catch (err) {
      this.snackBar.open(`Error initializing retailer detection: ${err}`, 'Dismiss', { duration: 3000, });
    }
  }

  async pageEvent(pageEvent: PageEvent): Promise<void> {
    this.isLoading = true;
    this.dataSource.data = [];
    const response = await this.parseService.getReceipts(Parse.User.current().id, pageEvent.pageIndex, pageEvent.pageSize);
    this.receipts = response.results;
    this.paginator.length = response.count;
    this.dataSource = new MatTableDataSource<Parse.Object<Parse.Attributes>>(this.receipts);
    this.isLoading = false;

    this.receipts = await this.parseService.updateReceiptsWithImageUrls(this.receipts);
  }



}
