import { Component, OnInit } from '@angular/core';
import { firebase } from 'firebaseui-angular';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  get isLoggedIn(): boolean {
    return !!firebase.auth().currentUser;
  }

  get isAnonymous(): boolean {
    const user = firebase.auth().currentUser;
    if (user) {
      if (user.isAnonymous) {
        return true;
      }
    }
    return false;
  }

}
