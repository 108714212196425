import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../../shared/shared.module';
import { ShoppingRoutingModule } from './shopping-routing.module';

import { ListPageComponent } from './list-page/list-page.component';
import { NewShoppingPageComponent } from './new-shopping-page/new-shopping-page.component';
import { DetailsPageComponent } from './details-page/details-page.component';
import { NewShoppingProductPageComponent } from './new-shopping-product-page/new-shopping-product-page.component';
import { NewShoppingProductBarcodeComponent } from './new-shopping-product-barcode/new-shopping-product-barcode.component';
import { CartsComponent } from './carts/carts.component';
import { NewCartPageComponent } from './new-cart-page/new-cart-page.component';
import { NewCartItemPageComponent } from './new-cart-item-page/new-cart-item-page.component';
import { CartItemsPageComponent } from './cart-items-page/cart-items-page.component';


@NgModule({
  declarations: [
    ListPageComponent,
    NewShoppingPageComponent,
    DetailsPageComponent,
    NewShoppingProductPageComponent,
    NewShoppingProductBarcodeComponent,
    CartsComponent,
    NewCartPageComponent,
    NewCartItemPageComponent,
    CartItemsPageComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ShoppingRoutingModule
  ]
})
export class ShoppingModule { }
