<div fxLayout="row" fxLayoutAlign="center">
  <h1>New cart</h1>
</div>
<div fxLayout="row" class="actions">
  <span fxLayoutAlign="start center">
    <a class="link" [routerLink]="['../../../../']">Shoppings</a>
    <span>&nbsp;&nbsp;/&nbsp;&nbsp;</span>
    <a class="link" [routerLink]="['../']">Carts</a>
  </span>
  <span class="fill-space"></span>
  <button mat-raised-button color="accent" [routerLink]="['../']">
    Back
  </button>
</div>
<div fxLayout="row" fxLayoutAlign="center">
  <mat-card>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <mat-form-field>
        <input
          matInput
          formControlName="shoppingProduct"
          placeholder="Shopping Product"
          autocomplete="off"
          type="text"
          [matAutocomplete]="auto"
        />
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayShoppingProductTranslation">
          <mat-option *ngFor="let shoppingProduct of shoppingProducts | async" [value]="shoppingProduct">
            <span>
              {{ shoppingProduct?.get('product')?.get('defaultTranslation')?.get('title') }}
            </span>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          formControlName="quantity"
          placeholder="Quantity"
          type="number"
        />
      </mat-form-field>

      <div fxLayoutAlign="center">
        <button
          mat-raised-button
          color="accent"
          type="submit"
          [disabled]="form.invalid || loading"
          fxLayoutAlign="center"
        >
          Submit
        </button>
      </div>
    </form>
  </mat-card>
</div>
