import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { UserRoutingModule } from './user-routing.module';

import { LoginPageComponent } from './login-page/login-page.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { FirebaseUIModule } from 'firebaseui-angular';
import { HttpClientModule } from '@angular/common/http';


@NgModule({
  declarations: [LoginPageComponent, LoginComponent, LogoutComponent],
  imports: [
    CommonModule,
    SharedModule,
    HttpClientModule,
    UserRoutingModule,
    FirebaseUIModule
  ]
})
export class UserModule { }
