import {Injectable} from '@angular/core';

const PREVIEW_ICON_TRANSFORMATIONS = {
  XS: 'tr:w-20,h-20,c-at_max',
  S: 'tr:w-32,h-32,c-at_max'
};

const PREVIEW_ICON_PREFIX = 'https://ik.imagekit.io/scanshop/';

@Injectable({
  providedIn: 'root'
})
export class ShoppingUtils {

  // getProductImageUrl(productTranslation: Parse.Attributes): string {
  //   if (productTranslation && productTranslation.has('productImages') && Array.isArray(productTranslation.get('productImages'))) {
  //     const productImage = productTranslation.get('productImages').find(el => el.get('order') === 0);
  //     return productImage ? 'https://ik.imagekit.io/scanshop/tr:w-20,h-20,c-at_max/' + productImage.get('image').name() : '' ;
  //   }
  // }

  getProductImageUrl(attributes: Parse.Attributes, size = 'XS'): string {
    const sufix = attributes?.get('productImage')?.get('image')?.name();
    const url = sufix ? `${PREVIEW_ICON_PREFIX}${PREVIEW_ICON_TRANSFORMATIONS[size]}/${sufix}` : '';
    return url;
  }

}
