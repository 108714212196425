<div fxLayout="row" fxLayoutAlign="center">
  <h1>Receipt versions</h1>
</div>
<div fxLayout="row" class="actions">
  <span fxLayoutAlign="start center">
    <a class="link" [routerLink]="['../']">Receipts</a>
  </span>
  <span class="fill-space"></span>
  <button mat-raised-button color="warn" (click)="openFinalizeDialog()" [disabled]="receipt ? receipt.get('isFinalized') : true" style="margin-right: 10px;">
    Finalize
  </button>
  <button mat-raised-button color="warn" (click)="openLockCorrectionDialog()" [disabled]="receipt ? !receipt.get('isFinalized') || receipt.get('isCorrected') : true" style="margin-right: 10px;">
    Lock Correction
  </button>
  <button mat-raised-button color="accent" [disabled]="receipt ? receipt.get('isFinalized') : true" routerLink="./version/new">
    New
  </button>
</div>
<div fxLayout="row" fxLayoutAlign="center">
  <table mat-table [dataSource]="receiptVersionsDataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>Id</th>
      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
    </ng-container>
    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef>Created At</th>
      <td mat-cell *matCellDef="let element">{{ element.createdAt.toLocaleString("hr-HR") }}</td>
    </ng-container>
    <ng-container matColumnDef="version">
      <th mat-header-cell *matHeaderCellDef>Version</th>
      <td mat-cell *matCellDef="let element">{{ element.get('version') }}</td>
    </ng-container>
    <ng-container matColumnDef="retailer">
      <th mat-header-cell *matHeaderCellDef>Retailer</th>
      <td mat-cell *matCellDef="let element">
        {{
          element.id === retailerImage?.get('receiptScanVersion').id
            ? this.receipt.get('retailer').get('code')
            : ''
        }}
      </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td
        mat-cell
        *matCellDef="let element"
      >
        <a class="link" [routerLink]="['./version', element.id]">Details</a>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="receiptVersionsDisplayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: receiptVersionsDisplayedColumns"></tr>
  </table>
</div>


<div *ngIf="isLockingCorrectionInProgress" class="locking-correction-in-progress-message" >
  <h3>Locking `ReceiptItems` Correction in progress...</h3>
  <p>
    Please wait for several seconds until creation of related `Expense` and `ExpenseItem`(s) objects are finished.
  </p>
</div>


<div *ngIf="isFinalizingInProgress" class="finalization-in-progress-message" >
  <h3>Finalizing `Receipt` in progress...</h3>
  <p>
    Please wait for several seconds until creation of `ReceiptScanItems` is finished.
  </p>
</div>

<ng-container *ngIf="receipt?.get('isFinalized') && receiptItemsAndCorrectionsDataSource">
  <div fxLayout="row" class="receipt-info">
    <mat-card style="width: 100%; box-sizing: border-box;">
      <h3>Receipt info</h3>
      <p>Total amount: {{receipt.get('totalAmount')}}</p>
      <p>Tax amount: {{receipt.get('taxAmount')}}</p>
      <p>Receipt UID (Croatian JIR): {{receipt.get('receiptUID')}}</p>
      <p>Is Finalized: {{ receipt.get('isFinalized') ? 'YES' : 'NO' }}</p>
      <p>Is Corrected: {{ receipt.get('isCorrected') ? 'YES' : 'NO' }}</p>
      <p>Checking Legality URL: <a [href]="receipt.get('checkingLegalityURL')" target="_blank" style="color: red;" >{{receipt.get('checkingLegalityURL')}}</a></p>
      <h4>Receipt scan items</h4>
      <div class="table-responsive">
        <table mat-table [dataSource]="receiptItemsAndCorrectionsDataSource" class="mat-elevation-z4">
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>Id</th>
            <td mat-cell *matCellDef="let element">{{ element.id }}</td>
          </ng-container>
          <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef>Title</th>
            <td mat-cell *matCellDef="let element">{{ element.get('title') }}</td>
          </ng-container>
          <ng-container matColumnDef="title-correction">
            <th mat-header-cell *matHeaderCellDef>Title corr.</th>
            <td mat-cell *matCellDef="let element">{{ element.get('receiptScanItemCorrection')?.get('title') }}</td>
          </ng-container>
          <ng-container matColumnDef="unitPrice">
            <th mat-header-cell *matHeaderCellDef>Unit price</th>
            <td mat-cell *matCellDef="let element">{{ element.get('unitPrice') }}</td>
          </ng-container>
          <ng-container matColumnDef="quantity">
            <th mat-header-cell *matHeaderCellDef>Quantity</th>
            <td mat-cell *matCellDef="let element">{{ element.get('quantity') }}</td>
          </ng-container>
          <ng-container matColumnDef="totalPrice">
            <th mat-header-cell *matHeaderCellDef>Total price</th>
            <td mat-cell *matCellDef="let element">{{ element.get('totalPrice') }}</td>
          </ng-container>
          <ng-container matColumnDef="product">
            <th mat-header-cell *matHeaderCellDef>Product detected</th>
            <td mat-cell *matCellDef="let element">
              <ng-container *ngIf="utils.getProductImageUrl(element.get('product')?.get('defaultTranslation'), 'S') ||
                              utils.getProductImageUrl(element.get('product'), 'S') as productImageUrl">
                <img [src]="productImageUrl" class="preview-image" />
              </ng-container>
              {{ element.get('product')?.get('defaultTranslation')?.get('title') || (element.get('product')?.id ? 'ID: ' + element.get('product')?.id : null) }}
            </td>
          </ng-container>
          <ng-container matColumnDef="product-correction">
            <th mat-header-cell *matHeaderCellDef>Product corr.</th>
            <td mat-cell *matCellDef="let element">
              <ng-container *ngIf="utils.getProductImageUrl(element.get('receiptScanItemCorrection')?.get('product'), 'S') as productImageUrl">
                <img [src]="productImageUrl" class="preview-image" />
              </ng-container>
              {{ element.get('receiptScanItemCorrection')?.get('product')?.get('defaultTranslation')?.get('title') || (element.get('receiptScanItemCorrection')?.get('product')?.id ? 'ID: ' + element.get('receiptScanItemCorrection')?.get('product')?.id : null) }}
            </td>
          </ng-container>
          <ng-container matColumnDef="category">
            <th mat-header-cell *matHeaderCellDef>Category detected</th>
            <td mat-cell *matCellDef="let element">{{ element.get('category')?.get('code') }}</td>
          </ng-container>
          <ng-container matColumnDef="category-correction">
            <th mat-header-cell *matHeaderCellDef>Category corr.</th>
            <td mat-cell *matCellDef="let element">{{ element.get('receiptScanItemCorrection')?.get('category')?.get('code') }}</td>
          </ng-container>
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let element" >
              <a *ngIf="!(receipt?.get('isCorrected'))" class="link" (click)="openCorrectionDialog(element)">Correct</a>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="receiptItemsDisplayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: receiptItemsDisplayedColumns"></tr>
        </table>
      </div>
    </mat-card>
  </div>
</ng-container>
