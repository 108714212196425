import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListPageComponent } from './list-page/list-page.component';
import { FormComponent } from './form/form.component';


const routes: Routes = [
  { path: '', component: ListPageComponent },
  { path: 'new', component: FormComponent },
  { path: ':id', component: FormComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RetailerRoutingModule { }
