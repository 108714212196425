import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ParseService } from '../../../../scan-data/parse-service/parse-service.service';

@Component({
  selector: 'scanshop-new-image-page',
  templateUrl: './new-image-page.component.html',
  styleUrls: ['./new-image-page.component.scss']
})
export class NewImagePageComponent implements OnInit {

  receiptVersionId: string;

  constructor(private router: Router, private route: ActivatedRoute, private parseService: ParseService) { }

  ngOnInit(): void {
  }

  async startUpload(file: File) {
    this.receiptVersionId = this.route.snapshot.paramMap.get('id');
    await this.parseService.createReceiptScanImage(file, this.receiptVersionId);
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

}
