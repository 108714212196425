import { Component, OnInit } from '@angular/core';
import { firebase } from 'firebaseui-angular';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  get currentUsername(): string {
    const user = firebase.auth().currentUser;
    return user.displayName || user.email || user.phoneNumber || 'Anonymous';
  }

  async onLogout(): Promise<void> {
    try {
      await Parse.User.logOut();
      await firebase.auth().signOut();
    } catch (err) {
      console.error('Error', err);
    }
  }
}
