import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

import { ParseService } from '../../../../scan-data/parse-service/parse-service.service';
import { ShoppingUtils } from '../shopping-utils';

@Component({
  selector: 'app-cart-items-page',
  templateUrl: './cart-items-page.component.html',
  styleUrls: ['./cart-items-page.component.scss']
})
export class CartItemsPageComponent implements OnInit {

  cartId: string;
  displayedColumns: string[] = ['id', 'image', 'item', 'quantity', 'createdAt', 'actions'];
  dataSource: MatTableDataSource<Parse.Object<Parse.Attributes>>;
  cartItems: Parse.Object[];
  isLoading = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    private route: ActivatedRoute,
    private parseService: ParseService,
    public utils: ShoppingUtils
  ) { }

  async ngOnInit(): Promise<void> {
    this.cartId = this.route.snapshot.paramMap.get('id');
    this.dataSource = new MatTableDataSource<Parse.Object<Parse.Attributes>>();
    this.dataSource.paginator = this.paginator;

    this.pageEvent({ pageIndex: 0, pageSize: 5, length: 0 });
  }

  async pageEvent(pageEvent: PageEvent): Promise<void> {
    this.isLoading = true;
    this.dataSource.data = [];
    const response = await this.parseService.getCartItems(this.cartId, pageEvent.pageIndex, pageEvent.pageSize);
    this.cartItems = response.results;
    this.paginator.length = response.count;
    this.dataSource = new MatTableDataSource<Parse.Object<Parse.Attributes>>(this.cartItems);
    this.isLoading = false;
  }

  async removeCartItem(item: Parse.Object): Promise<void> {
    this.isLoading = true;
    await this.parseService.removeCartItem(item.id);
    this.isLoading = false;

    this.pageEvent({ pageIndex: 0, pageSize: 5, length: 0 });
  }

}
