import { Component, OnInit } from '@angular/core';
import { firebase } from 'firebaseui-angular';
import { UserUtils } from '../app/ui/user/user-utils'

@Component({
  selector: 'scanshop-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    firebase.auth().onAuthStateChanged(UserUtils.customParseLogin);
  }
}
