<div fxLayout="row" fxLayoutAlign="center">
  <h1>New receipt</h1>
</div>
<div fxLayout="row" class="actions">
  <span fxLayoutAlign="start center">
    <a class="link" [routerLink]="['../']">Receipts</a>
  </span>
  <span class="fill-space"></span>
  <button mat-raised-button color="accent" [routerLink]="['../']">
    Back
  </button>
</div>
<scanshop-file-upload (startUpload)="startUpload($event)"></scanshop-file-upload>
