<div fxLayout="row" fxLayoutAlign="center">
  <h1>Cart items</h1>
</div>
<div fxLayout="row" fxLayoutAlign="end" class="actions">
  <span fxLayoutAlign="start center">
    <a class="link" [routerLink]="['../../../']">Shoppings</a>
    <span>&nbsp;&nbsp;/&nbsp;&nbsp;</span>
    <a class="link" [routerLink]="['../']">Carts</a>
  </span>
  <span class="fill-space"></span>
  <button mat-raised-button color="accent" routerLink="./new">
    New cart item
  </button>
</div>
<div fxLayout="row" fxLayoutAlign="center">
  <div class="table-responsive">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>Id</th>
        <td mat-cell *matCellDef="let element">{{ element.id }}</td>
      </ng-container>
      <ng-container matColumnDef="image">
        <th mat-header-cell *matHeaderCellDef class="text-center">Image</th>
        <td mat-cell *matCellDef="let element" class="text-center">
          <ng-container *ngIf="utils.getProductImageUrl(element.get('productAtStore')?.get('product'), 'S') ||
                                utils.getProductImageUrl(element.get('productAtStore')?.get('product')?.get('defaultTranslation'), 'S') as productImageUrl">
            <img [src]="productImageUrl" class="preview-image" />
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="item">
        <th mat-header-cell *matHeaderCellDef>Item</th>
        <td mat-cell *matCellDef="let element">{{ element.get('productAtStore')?.get('product')?.get('defaultTranslation')?.get('title') }}</td>
      </ng-container>
      <ng-container matColumnDef="quantity">
        <th mat-header-cell *matHeaderCellDef>Quantity</th>
        <td mat-cell *matCellDef="let element">{{ element.get('quantity') }}</td>
      </ng-container>
      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef>Created At</th>
        <td mat-cell *matCellDef="let element">{{ element.createdAt.toLocaleString("hr-HR") }}</td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let element">
          <a class="link" (click)="removeCartItem(element)">Remove</a>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <div *ngIf="isLoading" class="progress-spinner">
      <mat-progress-spinner
        diameter=20
        color="primary"
        mode="indeterminate">
      </mat-progress-spinner>
    </div>
    <div *ngIf="isLoading === false && paginator.length === 0" class="no-records">No records found</div>
  </div>
</div>
<div fxLayout="row" fxLayoutAlign="center">
  <mat-paginator
    [pageSizeOptions]="[5, 10, 20]"
    showFirstLastButtons
    (page)="pageEvent($event)"
  ></mat-paginator>
</div>

