import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';

import { ParseService } from '../../../../scan-data/parse-service/parse-service.service';

@Component({
  selector: 'app-scanshop-version-page',
  templateUrl: './version-page.component.html',
  styleUrls: ['./version-page.component.scss']
})
export class VersionPageComponent implements OnInit {

  receiptScanVersion: Parse.Object;
  displayedColumns: string[] = ['id', 'createdAt', 'retailer', 'image', 'preview'];
  dataSource: MatTableDataSource<Parse.Object<Parse.Attributes>>;
  imgPrefix = 'https://ik.imagekit.io/scanshop/tr:w-512,h-512,c-at_max/';

  receipt: Parse.Object<Parse.Attributes>;
  retailerImageId: string;

  constructor(private route: ActivatedRoute, private parseService: ParseService) { }

  async ngOnInit(): Promise<void> {
    const receiptScanVersionId = this.route.snapshot.paramMap.get('id');
    this.receiptScanVersion = await this.parseService.getReceiptScanVersion(receiptScanVersionId);

    const receiptScanVersionImages = await this.parseService.getReceiptScanVersionImages(this.receiptScanVersion);
    this.dataSource = new MatTableDataSource<Parse.Object<Parse.Attributes>>(receiptScanVersionImages);

    this.receipt = await this.parseService.getReceipt(this.receiptScanVersion.get('receipt').id);
    if (this.receipt.get('retailerDetectedFromImage')) {
      this.retailerImageId = this.receipt.get('retailerDetectedFromImage').id;
    }
  }

}
