import { literal } from '@angular/compiler/src/output/output_ast';
import { Injectable } from '@angular/core';
import {
  Customer,
  Language,
  Product,
  ProductTranslation,
  Receipt,
  ReceiptScanImage,
  ReceiptScanVersion,
  User,
  Retailer,
  Company,
  Country,
  Shopping,
  ShoppingProduct,
  Category,
  CategoryTranslation,
  ProductBarcode,
  ReceiptScanItem,
  ReceiptScanItemCorrection,
  ShoppingProductSearch,
  Cart,
  CartItem,
  StoreTranslation,
  ProductAtStore,
  ReceiptType,
} from '../model/parse.model';

// TODO: encapsulate in custom module
@Injectable({
  providedIn: 'root'
})
export class ParseService {

  constructor() { }

  async getCurrentCustomer(): Promise<Parse.Object> {
    const currentUser = Parse.User.current();
    const currentCustomer = currentUser.get('customer');

    if (!!currentCustomer) {
      return currentCustomer;
    }

    const queryCustomer = new Parse.Query(Customer);
    const userPointer = User.createWithoutData(currentUser.id);
    queryCustomer.equalTo('user', userPointer);
    return await queryCustomer.first();

  }

  async getProductTranslationByCode(productCode: string, languageCode?: string): Promise<Parse.Object> {

    const queryProductTranslation = new Parse.Query(ProductTranslation);

    if (languageCode) {
      const queryLanguage = new Parse.Query(Language);
      queryLanguage.equalTo('code', languageCode);
      queryProductTranslation.matchesQuery('language', queryLanguage);
    }

    const queryProduct = new Parse.Query(Product);
    queryProduct.equalTo('code', productCode);

    queryProductTranslation.matchesQuery('product', queryProduct);
    queryProductTranslation.include('product');

    try {
      const productTranslation = await queryProductTranslation.first();
      return productTranslation;
    } catch (err) {
      console.error('Error', err);
    }
  }

  async getReceipts(userId: string, pageIndex: number, pageSize: number): Promise<any> {

    const queryReceipt = new Parse.Query(Receipt);

    const queryCustomer = new Parse.Query(Customer);
    const userPointer = User.createWithoutData(userId);
    queryCustomer.equalTo('user', userPointer);
    queryReceipt.matchesQuery('createdBy', queryCustomer);
    queryReceipt.include('retailer');
    queryReceipt.descending('createdAt');
    queryReceipt.skip(pageIndex * pageSize);
    queryReceipt.limit(pageSize);
    (queryReceipt as any).withCount();

    try {
      const receipts = await queryReceipt.find();
      return receipts;
    } catch (err) {
      console.error('Error', err);
    }
  }

  async getReceiptScanVersion(receiptScanVersionId: string): Promise<Parse.Object<Parse.Attributes>> {

    const queryReceiptScanVersion = new Parse.Query(ReceiptScanVersion);
    try {
      const receiptScanVersion = await queryReceiptScanVersion.get(receiptScanVersionId);
      return receiptScanVersion;
    } catch (err) {
      console.error('Error', err);
    }
  }

  async getReceiptScanVersions(receiptId: string): Promise<Parse.Object<Parse.Attributes>[]> {

    const queryReceiptScanVersion = new Parse.Query(ReceiptScanVersion);
    const receiptPointer = Receipt.createWithoutData(receiptId);
    queryReceiptScanVersion.equalTo('receipt', receiptPointer);
    queryReceiptScanVersion.descending('createdAt');

    try {
      const receiptScanVersions = await queryReceiptScanVersion.find();
      return receiptScanVersions;
    } catch (err) {
      console.error('Error', err);
    }
  }

  async getReceiptScanVersionImages(receiptScanVersion: Parse.Object<Parse.Attributes>): Promise<Parse.Object<Parse.Attributes>[]> {

    const queryReceiptScanImage = new Parse.Query(ReceiptScanImage);
    queryReceiptScanImage.equalTo('receiptScanVersion', receiptScanVersion);
    queryReceiptScanImage.ascending('createdAt');

    try {
      const receiptScanImages = await queryReceiptScanImage.find();
      return receiptScanImages;
    } catch (err) {
      console.error('Error', err);
    }
  }

  async createReceipt(file: File): Promise<Parse.Object> {

    const currentCustomerPointer = await this.getCurrentCustomer();

    const receipt = new Receipt();
    receipt.set('receiptType', ReceiptType.createWithoutData('SCAN'));
    receipt.set('createdBy', currentCustomerPointer);
    receipt.setACL(new Parse.ACL(Parse.User.current()));

    try {
      await receipt.save();
      await this.createReceiptScanVersion(file, receipt.id);
      return receipt;
    } catch (err) {
      console.error('Error', err);
    }
  }

  async createReceiptScanVersion(file: File, receiptId: string): Promise<Parse.Object> {

    const receiptPointer = Receipt.createWithoutData(receiptId);

    const receiptScanVersion = new ReceiptScanVersion();
    receiptScanVersion.set('receipt', receiptPointer);
    receiptScanVersion.setACL(new Parse.ACL(Parse.User.current()));
    try {
      await receiptScanVersion.save();
      await this.createReceiptScanImage(file, receiptScanVersion.id);
      return receiptScanVersion;
    } catch (err) {
      console.error('Error', err);
    }
  }

  async createReceiptScanImage(file: File, receiptVersionId: string): Promise<Parse.Object> {

    // replace all characters except numbers, letters and '.' with '_'
    const fileName = file.name.replace(/[^a-zA-Z0-9/.]/g, '_');
    const parseFile = new Parse.File(fileName, file);

    const receiptScanVersionPointer = ReceiptScanVersion.createWithoutData(receiptVersionId);

    const receiptScanImage = new ReceiptScanImage();
    receiptScanImage.set('receiptScanVersion', receiptScanVersionPointer);
    receiptScanImage.set('image', parseFile);
    // magic strings to start server side OCR (look ParseCloud trigger -> ReceiptScanImage.afterSave())
    receiptScanImage.set('ocrStringFromBlinkReceiptAPI', 'REFRESH');
    receiptScanImage.set('ocrStringFromOCRSpaceAPI', 'REFRESH');
    receiptScanImage.setACL(new Parse.ACL(Parse.User.current()));


    try {
      await receiptScanImage.save();
      return receiptScanImage;
    } catch (err) {
      console.error('Error', err);
    }
  }

  async getRetailers(pageIndex: number, pageSize: number): Promise<any> {
    const queryRetailer = new Parse.Query(Retailer);
    queryRetailer.descending('createdAt');
    queryRetailer.skip(pageIndex * pageSize);
    queryRetailer.limit(pageSize);
    (queryRetailer as any).withCount();

    try {
      const retailers = await queryRetailer.find();
      return retailers;
    } catch (err) {
      console.error('Error', err);
    }
  }

  async getRetailer(retailerId: string): Promise<Parse.Object<Parse.Attributes>> {
    const queryRetailer = new Parse.Query(Retailer);
    queryRetailer.include(['company', 'company.country']);

    try {
      const retailer = await queryRetailer.get(retailerId);
      return retailer;
    } catch (err) {
      console.error('Error', err);
    }
  }

  async getCompanies(): Promise<Parse.Object<Parse.Attributes>[]> {
    const queryCompanies = new Parse.Query(Company);
    queryCompanies.descending('createdAt');

    try {
      const companies = await queryCompanies.find();
      return companies;
    } catch (err) {
      console.error('Error', err);
    }
  }

  async getShoppings(userId: string, pageIndex: number, pageSize: number): Promise<any> {

    const queryShopping = new Parse.Query(Shopping);

    const queryCustomer = new Parse.Query(Customer);
    const userPointer = User.createWithoutData(userId);
    queryCustomer.equalTo('user', userPointer);
    queryShopping.matchesQuery('owner', queryCustomer);
    queryShopping.descending('createdAt');
    queryShopping.skip(pageIndex * pageSize);
    queryShopping.limit(pageSize);
    (queryShopping as any).withCount();

    try {
      const shoppings = await queryShopping.find();
      return shoppings;
    } catch (err) {
      console.error('Error', err);
    }
  }

  async getCountries(): Promise<Parse.Object<Parse.Attributes>[]> {
    const queryCountries = new Parse.Query(Country);
    queryCountries.descending('createdAt');

    try {
      const countries = await queryCountries.find();
      return countries;
    } catch (err) {
      console.error('Error', err);
    }
  }

  async createShopping(title: string): Promise<Parse.Object> {

    const currentCustomerPointer = await this.getCurrentCustomer();

    const shopping = new Shopping();
    shopping.set('title', title);
    shopping.set('owner', currentCustomerPointer);
    shopping.setACL(new Parse.ACL(Parse.User.current()));

    try {
      await shopping.save();
      return shopping;
    } catch (err) {
      console.error('Error', err);
    }
  }

  async createOrUpdateRetailer(retailerCode: string, companyId: string, receiptVersions: any,
    retailer?: Parse.Object<Parse.Attributes>): Promise<Parse.Object> {
    const companyPointer = Company.createWithoutData(companyId);

    if (!retailer) {
      retailer = new Retailer();
    }
    retailer.set('code', retailerCode);
    retailer.set('company', companyPointer);
    retailer.set('receiptVersions', receiptVersions);

    await retailer.save();
    return retailer;
  }

  async createCompany(companyCode: string, countryId: string): Promise<Parse.Object> {
    const countryPointer = Country.createWithoutData(countryId);

    const company = new Company();
    company.set('code', companyCode);
    company.set('country', countryPointer);

    await company.save();
    return company;
  }

  async refreshRetailerDetection(receiptId: string): Promise<Parse.Object> {
    const receipt = await new Parse.Query(Receipt).get(receiptId);

    receipt.unset('retailer');
    receipt.unset('retailerDetectedFromImage');
    receipt.set('refreshRetailerDetection', 'REFRESH');

    await receipt.save();
    return receipt;
  }

  private getShoppingProductsQuery(shoppingId: string): Parse.Query {
    const queryShoppingProducts = new Parse.Query(ShoppingProduct);
    const shoppingPointer = Shopping.createWithoutData(shoppingId);
    queryShoppingProducts.equalTo('shopping', shoppingPointer);
    queryShoppingProducts.include('product');
    queryShoppingProducts.include('product.productImage');
    queryShoppingProducts.include('productTranslation');
    queryShoppingProducts.include('productTranslation.productImage');
    queryShoppingProducts.include('category');
    queryShoppingProducts.include('categoryTranslation');
    queryShoppingProducts.descending('createdAt');
    return queryShoppingProducts;
  }

  async getShoppingProducts(shoppingId?: string): Promise<Parse.Object<Parse.Attributes>[]> {
    const queryShoppingProducts = this.getShoppingProductsQuery(shoppingId);
    try {
      const shoppingProducts = await queryShoppingProducts.find();
      return shoppingProducts;
    } catch (err) {
      console.error('Error', err);
    }
  }

  async getShoppingProductsSubscription(shoppingId?: string): Promise<Parse.LiveQuerySubscription> {
    const queryShoppingProducts = this.getShoppingProductsQuery(shoppingId);
    try {
      return await queryShoppingProducts.subscribe();
    } catch (err) {
      console.error('Error', err);
    }
  }

  async createShoppingProductAndSearch(shoppingId: string, value: any, literalQuery: string): Promise<void> {
    const currentCustomerPointer = await this.getCurrentCustomer();

    const shoppingPointer = Shopping.createWithoutData(shoppingId);

    const shoppingProduct = new ShoppingProduct();
    shoppingProduct.set('shopping', shoppingPointer);
    shoppingProduct.set('createdBy', currentCustomerPointer);

    const categoryId = value?.className && value.className === 'CategoryTranslation' && value.get('category').id;
    const productId = value?.className && value.className === 'ProductTranslation' && value.get('product').id;
    if (productId) {
      const productPointer = Product.createWithoutData(productId);
      shoppingProduct.set('product', productPointer);
      const productTranslationPointer = ProductTranslation.createWithoutData(value.id);
      shoppingProduct.set('productTranslation', productTranslationPointer);

      const queryCategoryTranslation = new Parse.Query(CategoryTranslation);
      queryCategoryTranslation.equalTo('category', value.get('product').get('category'));
      queryCategoryTranslation.equalTo('language', value.get('language'));
      const categoryTranslation = await queryCategoryTranslation.first();

      shoppingProduct.set('categoryTranslation', categoryTranslation);
    } else if (categoryId) {
      const categoryPointer = Category.createWithoutData(categoryId);
      shoppingProduct.set('category', categoryPointer);

      const queryCategoryTranslation = new Parse.Query(CategoryTranslation);
      queryCategoryTranslation.equalTo('category', value.get('category'));
      queryCategoryTranslation.equalTo('language', value.get('language'));
      const categoryTranslation = await queryCategoryTranslation.first();

      shoppingProduct.set('categoryTranslation', categoryTranslation);
    } else {
      shoppingProduct.set('query', literalQuery);
    }
    shoppingProduct.setACL(new Parse.ACL(Parse.User.current()));

    const shoppingProductSearch = new ShoppingProductSearch();
    shoppingProductSearch.set('search', literalQuery);
    shoppingProductSearch.setACL(new Parse.ACL(Parse.User.current()));

    const oldShoppingProduct = await this.getShoppingProductIfAlreadyExists(shoppingProduct);
    if (oldShoppingProduct) {
      oldShoppingProduct.increment('quantity');
      shoppingProductSearch.set('shoppingProduct', oldShoppingProduct);
    } else {
      shoppingProductSearch.set('shoppingProduct', shoppingProduct);
    }

    try {
      await shoppingProductSearch.save();
    } catch (err) {
      console.error('Error', err);
    }
  }

  async getShoppingProductIfAlreadyExists(shoppingProduct: Parse.Object): Promise<Parse.Object> {
    const query = new Parse.Query(ShoppingProduct);
    query.equalTo('shopping', shoppingProduct.attributes.shopping);
    query.equalTo('query', shoppingProduct.attributes.query);
    query.equalTo('product', shoppingProduct.attributes.product);
    query.equalTo('category', shoppingProduct.attributes.category);
    return await query.first();
  }

  async getProductTranslationByQuery(query: string, languageCode?: string): Promise<Parse.Attributes[]> {
    if (!query) { return []; }

    const queryProductTranslation = new Parse.Query(ProductTranslation);

    if (languageCode) {
      const queryLanguage = new Parse.Query(Language);
      queryLanguage.equalTo('code', languageCode);
      queryProductTranslation.matchesQuery('language', queryLanguage);
    }

    queryProductTranslation.fullText('title', query);
    // NOTE: only with $score in order but without in select server returns MongoError, because of that every field is listed in select
    queryProductTranslation.ascending('$score');
    queryProductTranslation.select('$score');
    queryProductTranslation.select('title');
    queryProductTranslation.select('productImage');
    queryProductTranslation.select('product');
    queryProductTranslation.select('product.category');
    queryProductTranslation.select('product.productImage');
    queryProductTranslation.select('language');
    queryProductTranslation.include('productImage');
    queryProductTranslation.include('product');
    queryProductTranslation.include('product.category');
    queryProductTranslation.include('product.productImage');

    // TODO: maybe increase this limit to higher number
    queryProductTranslation.limit(25);

    try {
      const productTranslations = await queryProductTranslation.find();
      // NOTE(stepanic:2020-09-29): refactored with pointer `productImage` at ProductTranslation
      // productTranslations.forEach(async productTranslation => {
      //   if (productTranslation && productTranslation.has('productImages')) {
      //     const productImages = productTranslation.get('productImages');
      //     productTranslation.set('productImages', Array.isArray(productImages) ? productImages : await productImages.query().find());
      //   }
      // });
      return productTranslations;
    } catch (err) {
      console.error('Error', err);
    }
  }

  async getCategoryTranslationByQuery(query: string, languageCode?: string): Promise<Parse.Attributes[]> {
    if (!query) { return []; }

    const queryCategoryTranslation = new Parse.Query(CategoryTranslation);

    if (languageCode) {
      const queryLanguage = new Parse.Query(Language);
      queryLanguage.equalTo('code', languageCode);
      queryCategoryTranslation.matchesQuery('language', queryLanguage);
    }

    queryCategoryTranslation.fullText('title', query);
    queryCategoryTranslation.ascending('$score');
    queryCategoryTranslation.select('$score');
    queryCategoryTranslation.select('title');
    queryCategoryTranslation.select('category');
    queryCategoryTranslation.select('language');
    queryCategoryTranslation.include('category');

    try {
      const categoryTranslations = await queryCategoryTranslation.find();
      return categoryTranslations;
    } catch (err) {
      console.error('Error', err);
    }
  }

  async getTranslationsByQuery(query: string, languageCode?: string): Promise<Parse.Attributes[]> {
    if (!query || typeof query !== 'string') { return []; }

    // TODO: maybe sort by $score to appear products with higher $score before categories with lower score
    // EXAMPLE: ozujsko pivo svijet (KIDS 0.75, but Ozujsko pivo svijetlo 1.2)
    const products = await this.getProductTranslationByQuery(query, languageCode);
    const categories = await this.getCategoryTranslationByQuery(query, languageCode);

    return categories.concat(products);
  }

  async getTranslationByBarcode(barcode: string, languageCode?: string): Promise<any> {
    if (!barcode) { return; }

    const queryProductTranslation = new Parse.Query(ProductTranslation);

    if (languageCode) {
      const queryLanguage = new Parse.Query(Language);
      queryLanguage.equalTo('code', languageCode);
      queryProductTranslation.matchesQuery('language', queryLanguage);
    }

    const queryProductBarcode = new Parse.Query(ProductBarcode);
    queryProductBarcode.equalTo('barcode', barcode);
    queryProductBarcode.include('product');

    queryProductTranslation.matchesKeyInQuery('product', 'product', queryProductBarcode);
    queryProductTranslation.include(['product', 'productImages', 'product.category']);

    try {
      const productTranslation = await queryProductTranslation.first();
      // NOTE: replaced with pointer ProductTranslation.productImage
      // if (productTranslation && productTranslation.has('productImages')) {
      //   const productImages = productTranslation.get('productImages');
      //   productTranslation.set('productImages', Array.isArray(productImages) ? productImages : await productImages.query().find());
      // }
      return productTranslation;
    } catch (err) {
      console.error('Error', err);
    }
  }

  async getShoppingProductTranslations(shoppingId: string): Promise<Parse.Attributes[]> {
    if (!shoppingId) { return []; }

    const shoppingPointer = Shopping.createWithoutData(shoppingId);
    const queryShoppingProduct = new Parse.Query(ShoppingProduct);
    queryShoppingProduct.equalTo('shopping', shoppingPointer);
    queryShoppingProduct.doesNotExist('cartItem');
    queryShoppingProduct.greaterThan('quantity', 0);
    queryShoppingProduct.include('product.defaultTranslation');

    try {
      const shoppingProducts = await queryShoppingProduct.find();
      return shoppingProducts;
    } catch (err) {
      console.error('Error', err);
    }
  }

  async updateReceiptsWithImageUrls(receipts: Parse.Object<Parse.Attributes>[]): Promise<Parse.Object<Parse.Attributes>[]> {
    const filteredReceipts = receipts.filter(receipt => !receipt.get('imageUrls') || !receipt.get('imageUrls').length);
    const receiptIds = filteredReceipts.map(obj => obj.id);

    const versionQuery = new Parse.Query(ReceiptScanVersion);
    versionQuery.containedIn('receipt', receiptIds);
    versionQuery.limit(10000);

    const versions = await versionQuery.find();

    const versionIds = new Set();
    versions.forEach(version => {
      const siblingReceiptVersions = versions.filter(obj => obj.get('receipt').id === version.get('receipt').id);
      const newestReceiptVersion = siblingReceiptVersions.reduce((a, b) => {
        return new Date(a.createdAt) > new Date(b.createdAt) ? a : b;
      });
      versionIds.add(newestReceiptVersion.id);
    });

    const imageQuery = new Parse.Query(ReceiptScanImage);
    imageQuery.containedIn('receiptScanVersion', Array.from(versionIds));
    imageQuery.limit(10000);
    const images = await imageQuery.find();

    filteredReceipts.forEach(receipt => receipt.set('imageUrls', []));
    images.forEach(image => {
      const version = versions.find(versionObj => versionObj.id === image.get('receiptScanVersion').id);
      const receipt = receipts.find(receiptObj => receiptObj.id === version.get('receipt').id);
      receipt.get('imageUrls').push(image.get('image')._url);
    });

    return receipts;
  }

  async getReceipt(receiptId: string): Promise<Parse.Object<Parse.Attributes>> {
    const query = new Parse.Query(Receipt);
    query.include('retailer');
    return await query.get(receiptId);
  }

  async getReceiptScanVersionImage(imageId: string): Promise<Parse.Object<Parse.Attributes>> {
    const query = new Parse.Query(ReceiptScanImage);
    return await query.get(imageId);
  }

  async getReceiptScanItemsAndCorrections(receiptScanVersion: Parse.Object<Parse.Attributes>): Promise<Parse.Object[]> {
    const queryItems = new Parse.Query(ReceiptScanItem);
    queryItems.equalTo('receiptScanVersion', receiptScanVersion);
    queryItems.include([
      'product',
      'product.productImage',
      'product.defaultTranslation',
      'product.defaultTranslation.title',
      'product.defaultTranslation.productImage',
      'category',
      'receiptScanItemCorrection',
      'receiptScanItemCorrection.product',
      'receiptScanItemCorrection.product.defaultTranslation',
      'receiptScanItemCorrection.product.productImage',
      'receiptScanItemCorrection.category'
    ]);
    return await queryItems.find();
  }

  async finalizeReceipt(receipt: Parse.Object<Parse.Attributes>): Promise<Parse.Object<Parse.Attributes>> {
    receipt.set('isFinalized', true);
    return await receipt.save();
  }

  async lockReceiptCorrection(receipt: Parse.Object<Parse.Attributes>): Promise<Parse.Object<Parse.Attributes>> {
    receipt.set('isCorrected', true);
    return await receipt.save();
  }

  /**
   * After receipt scanning product or category is detected but customer can edit this value to some other product or category
   * @param item
   * @param productOrCategory
   * @param title
   */
  async createOrUpdateReceiptScanItemCorrection(item: Parse.Object, productOrCategory: Parse.Object, title: string): Promise<void> {
    const correctionQuery = new Parse.Query(ReceiptScanItemCorrection);
    correctionQuery.equalTo('receiptScanItem', item);
    let correction: Parse.Object = item.get('receiptScanItemCorrection');
    if (!correction) {
      correction = new ReceiptScanItemCorrection();
    }

    correction.set('receiptScanItem', item);

    if (title) {
      correction.set('title', title);
    } else {
      correction.unset('title');
    }

    if (!productOrCategory) {
      correction.unset('category');
      correction.unset('product');
    } else if (productOrCategory.className === 'Category') {
      correction.set('category', productOrCategory);
      correction.unset('product');
    } else if (productOrCategory.className === 'Product') {
      correction.set('product', productOrCategory);
      correction.unset('category');
    }

    correction = await correction.save();

    await correction.fetchWithInclude('product.defaultTranslation')

    if (!item.get('receiptScanItemCorrection')) {
      item.set('receiptScanItemCorrection', correction);
      await item.save();
    }
  }

  async getCarts(shoppingId: string, pageIndex: number, pageSize: number): Promise<any> {

    const queryCart = new Parse.Query(Cart);

    const shoppingPointer = Shopping.createWithoutData(shoppingId);
    queryCart.equalTo('shopping', shoppingPointer);
    queryCart.include([
      'store.defaultTranslation'
    ]);
    queryCart.descending('createdAt');
    queryCart.skip(pageIndex * pageSize);
    queryCart.limit(pageSize);
    (queryCart as any).withCount();

    try {
      const carts = await queryCart.find();
      return carts;
    } catch (err) {
      console.error('Error', err);
    }
  }

  async createCart(shoppingId: string, storeTranslationId: string, status: string): Promise<Parse.Object> {

    const shoppingPointer = Shopping.createWithoutData(shoppingId);

    const queryStoreTranslation = new Parse.Query(StoreTranslation);
    queryStoreTranslation.equalTo("objectId", storeTranslationId);
    const storeTranslationPointer = await queryStoreTranslation.first();
    const storePointer = storeTranslationPointer.get('store');

    const currentCustomerPointer = await this.getCurrentCustomer();

    const cart = new Cart();
    cart.set('shopping', shoppingPointer);
    cart.set('store', storePointer);
    cart.set('status', status);
    cart.set('owner', currentCustomerPointer);
    cart.setACL(new Parse.ACL(Parse.User.current()));

    try {
      await cart.save();
      return cart;
    } catch (err) {
      console.error('Error', err);
    }
  }

  async getCartItems(cartId: string, pageIndex: number, pageSize: number): Promise<any> {

    const queryCartItem = new Parse.Query(CartItem);

    const cartPointer = Cart.createWithoutData(cartId);
    queryCartItem.equalTo('cart', cartPointer);
    queryCartItem.greaterThan('quantity', 0);
    queryCartItem.include([
      'cart',
      'productAtStore.product.defaultTranslation',
    ]);
    queryCartItem.descending('createdAt');
    queryCartItem.skip(pageIndex * pageSize);
    queryCartItem.limit(pageSize);
    (queryCartItem as any).withCount();

    try {
      const cartItems = await queryCartItem.find();
      return cartItems;
    } catch (err) {
      console.error('Error', err);
    }
  }

  async createCartItem(cartId: string, quantity: number, shoppingProductId?: string): Promise<Parse.Object> {

    const cartPointer = await this.getCart(cartId);
    const storePointer = cartPointer.attributes.store;
    const queryShoppingProduct = new Parse.Query(ShoppingProduct);
    queryShoppingProduct.equalTo("objectId", shoppingProductId);
    const shoppingProductPointer = await queryShoppingProduct.first();

    const productPointer = shoppingProductPointer.get('product');

    const queryProductAtStorePointer = new Parse.Query(ProductAtStore);
    queryProductAtStorePointer.equalTo("product", productPointer);
    queryProductAtStorePointer.equalTo("store", storePointer);
    const productAtStorePointer = await queryProductAtStorePointer.first();

    if (productAtStorePointer) {
      const currentCustomerPointer = await this.getCurrentCustomer();

      const cartItem = new CartItem();
      cartItem.set('cart', cartPointer);
      cartItem.set('quantity', quantity);
      cartItem.set('productAtStore', productAtStorePointer);
      cartItem.set('shoppingProduct', shoppingProductPointer);
      cartItem.set('createdBy', currentCustomerPointer);
      cartItem.setACL(new Parse.ACL(Parse.User.current()));

      try {
        await cartItem.save();

        shoppingProductPointer.set('quantity', quantity);
        shoppingProductPointer.set('cartItem', cartItem);
        await shoppingProductPointer.save();

        return cartItem;
      } catch (err) {
        console.error('Error', err);
      }

    }
    else {
      console.error('Product not available at current store');
    }
  }

  async removeCartItem(cartItemId: string): Promise<void> {

    const queryCartItem = new Parse.Query(CartItem);
    queryCartItem.equalTo("objectId", cartItemId);
    queryCartItem.include("shoppingProduct");
    const cartItem = await queryCartItem.first();

    if (cartItem) {

      const shoppingProduct = cartItem.get('shoppingProduct');
      if (shoppingProduct) {
        shoppingProduct.unset('cartItem');
      }

      cartItem.set('quantity', 0);
      cartItem.unset('shoppingProduct');

      try {
        await cartItem.save();
        if (shoppingProduct) {
          await shoppingProduct.save();
        }
      } catch (err) {
        console.error('Error', err);
      }
    }
    else {
      console.error('Cart item does not exist');
    }
  }

  async getCart(cartId: string): Promise<Parse.Attributes> {
    const queryCart = new Parse.Query(Cart);
    queryCart.equalTo("objectId", cartId);

    return await queryCart.first();
  }

  async getStoreTranslationsByQuery(query: string, languageCode?: string): Promise<Parse.Attributes[]> {
    if (!query || typeof query !== 'string') { return []; }

    const queryStoreTranslation = new Parse.Query(StoreTranslation);

    if (languageCode) {
      const queryLanguage = new Parse.Query(Language);
      queryLanguage.equalTo('code', languageCode);
      queryStoreTranslation.matchesQuery('language', queryLanguage);
    }

    queryStoreTranslation.fullText('title', query);
    // NOTE: only with $score in order but without in select server returns MongoError, because of that every field is listed in select
    queryStoreTranslation.ascending('$score');
    queryStoreTranslation.select('$score');
    queryStoreTranslation.select('title');
    queryStoreTranslation.select('store');
    queryStoreTranslation.select('language');

    // TODO: maybe increase this limit to higher number
    queryStoreTranslation.limit(25);

    try {
      const storeTranslations = await queryStoreTranslation.find();
      return storeTranslations;
    } catch (err) {
      console.error('Error', err);
    }
  }
}
