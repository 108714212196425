export const environment = {
    production: false,
    PARSE_APP_ID: 'scanShopApiDev',
    apiRootURL: 'https://api-dev.ss.hr',
    serverURL: 'https://api-dev.ss.hr/parse',
    liveQueryServerURL: 'wss://api-dev.ss.hr/parse',
    firebase: {
      apiKey: 'AIzaSyB30pg3wbMYOJ9zTG-mApg1_JFPmFKG3Bg',
      authDomain: 'scanshop.firebaseapp.com',
      databaseURL: 'https://scanshop.firebaseio.com',
      projectId: 'scanshop',
      storageBucket: 'scanshop.appspot.com',
      messagingSenderId: '1010124784747',
      appId: '1:1010124784747:web:35475100b5dac201749d2e',
      measurementId: 'G-MPDP7WRQLS'
    }
};
