<div fxLayout="row" fxLayoutAlign="center">
  <h1>New shopping product</h1>
</div>
<div fxLayout="row" class="actions">
  <span fxLayoutAlign="start center">
    <a class="link" [routerLink]="['../../../']">Shoppings</a>
    <span>&nbsp;&nbsp;/&nbsp;&nbsp;</span>
    <a class="link" [routerLink]="['../../']">Shopping products</a>
  </span>
  <span class="fill-space"></span>
  <button mat-raised-button color="accent" [routerLink]="['../new']">
    Search for specific product
  </button>
  <span>&nbsp;&nbsp;</span>
  <button mat-raised-button color="accent" [routerLink]="['../../']">
    Back
  </button>
</div>
<div fxLayout="row" fxLayoutAlign="center">
  <mat-card>
    <form [formGroup]="form" (ngSubmit)="onSubmit()" fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="space-between end" fxLayoutGap>
      <mat-form-field>
        <input
          matInput
          formControlName="barcode"
          placeholder="EAN-13 or EAN-8 barcode"
          type="text"
          pattern="[0-9]{13}|[0-9]{8}"
          autocomplete="off"
        />
      </mat-form-field>
      <button
        id="submit-button"
        mat-raised-button
        color="accent"
        type="submit"
        [disabled]="!productTranslation || loading"
        fxLayoutAlign="center"
      >
        Add to shopping list
      </button>
    </form>
    <div *ngIf="productTranslation" class="product" fxLayout="space-between center">
      <ng-container *ngIf="utils.getProductImageUrl(productTranslation) || utils.getProductImageUrl(productTranslation?.get('product')) as productImageUrl">
        <img [src]="productImageUrl" class="preview-image" />
      </ng-container>
      <span><small>Product: </small>{{productTranslation.get('title')}}</span>
    </div>
    <div id="interactive" class="viewport" [class.hide]="productTranslationIsFound">
      <!-- QuaggaJS will populate this element -->
    </div>
  </mat-card>
</div>
