import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { ReceiptModule } from './receipt/receipt.module';
import { RetailerModule } from './retailer/retailer.module';

import { HomePageComponent } from './home-page/home-page.component';
import { ShoppingModule } from './shopping/shopping.module';

@NgModule({
  declarations: [HomePageComponent],
  imports: [
    CommonModule,
    SharedModule,
    ReceiptModule,
    RetailerModule,
    ShoppingModule
  ],
  exports: []
})
export class PagesModule { }
