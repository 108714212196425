import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ParseService } from '../../../../scan-data/parse-service/parse-service.service';

@Component({
  selector: 'scanshop-new-version-page',
  templateUrl: './new-version-page.component.html',
  styleUrls: ['./new-version-page.component.scss']
})
export class NewVersionPageComponent implements OnInit {

  receiptId: string;

  constructor(private router: Router, private route: ActivatedRoute, private parseService: ParseService) { }

  ngOnInit(): void {
  }

  async startUpload(file: File) {
    this.receiptId = this.route.snapshot.paramMap.get('id');
    await this.parseService.createReceiptScanVersion(file, this.receiptId);
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

}
