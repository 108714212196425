import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { ParseService } from 'src/app/scan-data/parse-service/parse-service.service';
import { ShoppingUtils } from '../../../shopping/shopping-utils';

@Component({
  selector: 'app-correction-dialog',
  templateUrl: 'correction-dialog.component.html',
  styleUrls: ['./correction-dialog.component.scss']
})
export class CorrectionDialogComponent implements OnInit {

  form: FormGroup;
  translations: Observable<Parse.Attributes[]>;
  // NOTE: user's literal string value of query input (typed or pasted) but not selected from the autocomplete list
  literalQuery: string;
  item: Parse.Object;

  constructor(
    private parseService: ParseService,
    private formBuilder: FormBuilder,
    public utils: ShoppingUtils,
    private dialogRef: MatDialogRef<CorrectionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.item = data.item;
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      title: [''],
      query: [''],
    });

    this.form.get('title').setValue(this.item.get('title'));

    this.translations = this.query.valueChanges
      .pipe(
        debounceTime(300),
        switchMap(value => this.parseService.getTranslationsByQuery(value, 'hr'))
      );
  }

  get query(): AbstractControl {
    return this.form.get('query');
  }

  displayTranslation(translation: Parse.Attributes): string {
    if (translation) { return translation.get('title'); }
  }

  /**
   * Take the literal user's input for search query
   */
  public onInputQuery(): void {
    const value = this.query.value;
    this.literalQuery = value;
  }

  save(): void {
    this.dialogRef.close({
      form: this.form.value,
      item: this.item
    });
  }

  close(): void {
    this.dialogRef.close();
  }

}
