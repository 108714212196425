import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { SharedModule } from '../../shared/shared.module'
import { ReceiptRoutingModule } from './receipt-routing.module'

import { ListPageComponent } from './list-page/list-page.component'
import { DetailsPageComponent } from './details-page/details-page.component'
import { VersionPageComponent } from './version-page/version-page.component'
import { FinalizeDialogComponent } from './details-page/finalize-dialog/finalize-dialog.component'
import { LockCorrectionDialogComponent } from './details-page/lock-correction-dialog/lock-correction-dialog.component'
import { CorrectionDialogComponent } from './details-page/correction-dialog/correction-dialog.component'
import { NewReceiptPageComponent } from './new-receipt-page/new-receipt-page.component'
import { NewVersionPageComponent } from './new-version-page/new-version-page.component'
import { NewImagePageComponent } from './new-image-page/new-image-page.component'


@NgModule({
  declarations: [
    ListPageComponent,
    DetailsPageComponent,
    VersionPageComponent,
    NewReceiptPageComponent,
    NewVersionPageComponent,
    NewImagePageComponent,
    FinalizeDialogComponent,
    LockCorrectionDialogComponent,
    CorrectionDialogComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReceiptRoutingModule,
  ]
})
export class ReceiptModule { }
