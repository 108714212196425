<div fxLayout="row" fxLayoutAlign="center">
  <h1>Shoppings</h1>
</div>
<div fxLayout="row" fxLayoutAlign="end" class="actions">
  <button mat-raised-button color="accent" routerLink="./new">
    New shopping
  </button>
</div>
<div fxLayout="row" fxLayoutAlign="center">
  <div class="table-responsive">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>Id</th>
        <td mat-cell *matCellDef="let element">{{ element.id }}</td>
      </ng-container>
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef>Title</th>
        <td mat-cell *matCellDef="let element">{{ element.get('title') }}</td>
      </ng-container>
      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef>Created At</th>
        <td mat-cell *matCellDef="let element">{{ element.createdAt.toLocaleString("hr-HR") }}</td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let element">
          <a class="link" [routerLink]="element.id">Products </a>
          |
          <a class="link" [routerLink]="[element.id,'cart']">Carts </a>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <div *ngIf="isLoading" class="progress-spinner">
      <mat-progress-spinner
        diameter=20
        color="primary"
        mode="indeterminate">
      </mat-progress-spinner>
    </div>
    <div *ngIf="isLoading === false && paginator.length === 0" class="no-records">No records found</div>
  </div>
</div>
<div fxLayout="row" fxLayoutAlign="center">
  <mat-paginator
    [pageSizeOptions]="[5, 10, 20]"
    showFirstLastButtons
    (page)="pageEvent($event)"
  ></mat-paginator>
</div>

