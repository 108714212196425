import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { FormGroup } from '@angular/forms';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';

import { ParseService } from '../../../../scan-data/parse-service/parse-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {

  constructor(private route: ActivatedRoute, private parseService: ParseService, private snackBar: MatSnackBar) { }

  retailer: Parse.Object<Parse.Attributes>;

  form = new FormGroup({});
  model: any = {};
  options: FormlyFormOptions = {
    formState: {
      selectOptionsData: {
        countries: [],
        companies: [],
      },
    },
  };

  fields: FormlyFieldConfig[] = [
    {
      template: '<h3>Retailer</h3>',
    },
    {
      key: 'retailerCode',
      type: 'input',
      templateOptions: {
        label: 'Retailer code',
        required: true,
      }
    },
    {
      key: 'retailerTokensGroup',
      type: 'repeat',
      templateOptions: {
        addText: 'Add new receipt version',
        removeText: 'Remove',
        fieldTitle: 'Receipt version',
        min: 1,
      },
      validators: {
        receiptVersions: {
          expression: (c: any) => c.value.length > 0,
          message: (error: any, field: FormlyFieldConfig) => `Retailer should have at least one receipt version`,
        },
      },
      fieldArray: {
        fieldGroup: [
          {
            key: 'contains',
            type: 'chips',
            templateOptions: {
              label: 'String tokens the receipt must contain',
              required: true,
            }
          },
          {
            key: 'notContains',
            type: 'chips',
            templateOptions: {
              label: 'String tokens the receipt must NOT contain',
            }
          },
        ],
      }
    },
    {
      template: '<h3>Company</h3>',
    },
    {
      fieldGroup: [
        {
          key: 'companyExistingNew',
          type: 'radio',
          templateOptions: {
            label: 'Select existing company or create new',
            required: true,
            options: [
              { value: 'existing', label: 'Existing company' },
              { value: 'new', label: 'New company' },
            ],
          },
        },
      ],
    },
    {
      key: 'existingCompany',
      hideExpression: () => this.model.companyExistingNew !== 'existing',
      fieldGroup: [
        {
          key: 'companySelect',
          type: 'select',
          templateOptions: {
            label: 'Company',
            required: true,
          },
          expressionProperties: {
            'templateOptions.options': 'formState.selectOptionsData.companies',
          },
        },
      ],
    },
    {
      key: 'newCompany',
      hideExpression: () => this.model.companyExistingNew !== 'new',
      fieldGroup: [
        {
          key: 'companyCode',
          type: 'input',
          templateOptions: {
            label: 'Company code',
            required: true,
          }
        },
        {
          key: 'country',
          type: 'select',
          templateOptions: {
            label: 'Country',
            required: true,
          },
          expressionProperties: {
            'templateOptions.options': 'formState.selectOptionsData.countries',
          },
        },
      ],
    },
  ];

  async ngOnInit(): Promise<void> {
    const retailerId = this.route.snapshot.paramMap.get('id');
    if (retailerId) {
      this.retailer = await this.parseService.getRetailer(retailerId);
      this.populateFormFromDatabase();
    }

    await this.populateSelectOptions();
  }

  async populateSelectOptions(): Promise<void> {
    const companies = await this.parseService.getCompanies();
    const countries = await this.parseService.getCountries();

    this.options.formState.selectOptionsData.countries = countries.map(country => {
      return {
        value: country.id,
        label: country.get('name'),
      };
    });
    this.options.formState.selectOptionsData.companies = companies.map(company => {
      return {
        value: company.id,
        label: company.get('code'),
      };
    });
  }

  populateFormFromDatabase(): void {
    const model = {
      companyExistingNew: 'existing',
      retailerCode: this.retailer.get('code'),
      retailerTokensGroup: [],
      existingCompany: {
        companySelect: this.retailer.get('company').id,
      },
    };
    this.retailer.get('receiptVersions').forEach((receiptVersion: any) => {
      model.retailerTokensGroup.push({
        contains: receiptVersion?.general?.contains,
        notContains: receiptVersion?.products?.rsd?.notContains,
      });
    });
    this.model = model;
  }

  async submit(): Promise<void> {
    if (this.form.valid) {
      try {
        let companyId: string;
        if (this.model.companyExistingNew === 'new') {
          const company = await this.parseService.createCompany(this.model.newCompany.companyCode, this.model.newCompany.country);
          companyId = company.id;
        } else {
          companyId = this.model.existingCompany.companySelect;
        }

        const receiptVersions = this.getReceiptVersionsObjectFromModel();
        this.retailer = await this.parseService.createOrUpdateRetailer(this.model.retailerCode, companyId, receiptVersions, this.retailer);
        // Refreshes form to prevent resubmitting as new company
        this.populateFormFromDatabase();
        this.populateSelectOptions();
        this.snackBar.open('Retailer saved successfully', 'Dismiss', { duration: 3000, });
      } catch (err) {
        this.snackBar.open(`Error saving retailer: ${err}`, 'Dismiss', { duration: 3000, });
      }
    }
  }

  getReceiptVersionsObjectFromModel(): any {
    const receiptVersions = [];
    this.model.retailerTokensGroup.forEach(receiptVersion => {
      receiptVersions.push({
        general: {
          contains: receiptVersion.contains,
        },
        products: {
          rsd: {
            notContains: receiptVersion.notContains,
          },
        },
      });
    });
    return receiptVersions;
  }

}
