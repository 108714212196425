<div fxLayout="row" fxLayoutAlign="center">
  <h1>Shopping products</h1>
</div>
<div fxLayout="row" class="actions">
  <span fxLayoutAlign="start center">
    <a class="link" [routerLink]="['../']">Shoppings</a>
  </span>
  <span class="fill-space"></span>
  <button mat-raised-button color="accent" routerLink="./product/new">
    Add new shopping product
  </button>
</div>
<div fxLayout="row" fxLayoutAlign="center">
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>Id</th>
      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
    </ng-container>
    <ng-container matColumnDef="image">
      <th mat-header-cell *matHeaderCellDef class="text-center">Image</th>
      <td mat-cell *matCellDef="let element" class="text-center">
        <ng-container *ngIf="utils.getProductImageUrl(element.get('productTranslation'), 'S') ||
                              utils.getProductImageUrl(element.get('product'), 'S') as productImageUrl">
          <img [src]="productImageUrl" class="preview-image" />
        </ng-container>
      </td>
    </ng-container>
    <ng-container matColumnDef="item">
      <th mat-header-cell *matHeaderCellDef>Item</th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="element.get('product')">
          {{ element.get('productTranslation')?.get('title') || element.get('product')?.get('description') }} <small>(Product)</small>
        </ng-container>
        <ng-container *ngIf="element.get('category')">
          {{ element.get('categoryTranslation')?.get('title') || element.get('category').get('code') }} <small>(Category)</small>
        </ng-container>
        <ng-container *ngIf="element.get('query')">
          {{ element.get('query') }} <small>(Query)</small>
        </ng-container>
      </td>
    </ng-container>
    <ng-container matColumnDef="category">
      <th mat-header-cell *matHeaderCellDef>Category</th>
      <td mat-cell *matCellDef="let element">{{ element.get('category') && element.get('category').get('code') }}</td>
    </ng-container>
    <ng-container matColumnDef="query">
      <th mat-header-cell *matHeaderCellDef>Query</th>
      <td mat-cell *matCellDef="let element">{{ element.get('query') }}</td>
    </ng-container>
    <ng-container matColumnDef="quantity">
      <th mat-header-cell *matHeaderCellDef>Quantity</th>
      <td mat-cell *matCellDef="let element">{{ element.get('quantity') }}</td>
    </ng-container>
    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef>Created At</th>
      <td mat-cell *matCellDef="let element">{{ element.createdAt.toLocaleString("hr-HR") }}</td>
    </ng-container>
    <ng-container matColumnDef="version">
      <th mat-header-cell *matHeaderCellDef>Version</th>
      <td mat-cell *matCellDef="let element">{{ element.get('version') }}</td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
