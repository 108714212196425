import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { UiModule } from './ui/ui.module';

import { AppComponent } from './app.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { firebase, firebaseui, FirebaseUIModule } from 'firebaseui-angular';
import { UserUtils } from '../app/ui/user/user-utils';
import { NG_ENTITY_SERVICE_CONFIG } from '@datorama/akita-ng-entity-service';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { environment } from '../environments/environment'

firebase.initializeApp(environment.firebase);

const firebaseUiAuthConfig: firebaseui.auth.Config = {
  signInOptions: [
    'apple.com',
    {
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      scopes: ['email', 'profile']
    },
    firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
      forceSameDevice: true,
      emailLinkSignIn: function() {
        return {
          url: window.location.href,
          dynamicLinkDomain: 'scanshop.page.link',
          handleCodeInApp: true,
          iOS: {
            bundleId: 'hr.italk.scanshop'
          },
          android: {
            packageName: 'com.scanshop.android',
            installApp: true,
            minimumVersion: '21'
          }
        };
      }
    },
    {
      provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
      recaptchaParameters: {
        size: 'invisible',
      },
      defaultCountry: 'HR',
    },
    firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID,
  ],
  signInFlow: 'popup', // Kada je postavljeno na redirect, ne poziva se signInFailure callback kad se koristi google signin
  autoUpgradeAnonymousUsers: true,
  tosUrl: 'https://example.com/', // TODO
  privacyPolicyUrl: 'https://example.com/', // TODO
  callbacks: {
    // TODO: Callback sluzi za rjesavanje merge conflicta kada user stvori anonymous account
    // i onda ga proba povezati sa vec postojecim firebase accountom koji ima neke podatke
    // pa se mora odluciti koji podaci ostaju u Parse bazi a koji ne
    // (npr. CustomerProfile bi ostali podaci jednog usera, a ShoppingProduct bi trebali ostati podaci oba usera)
    // 11.12.20. U dogovoru s Matijom odluceno je da zasad ne pokrivamo ovaj use case
    // i da samo uzimamo podatke od postojeceg user accounta a podatke anonymous user accounta ignoriramo
    // https://github.com/firebase/firebaseui-web#upgrading-anonymous-users
    signInFailure: async function(error) {
      console.log(JSON.stringify(error));
      if (error.code != 'firebaseui/anonymous-upgrade-merge-conflict') {
        return Promise.resolve();
      }
      const anonymousUser = firebase.auth().currentUser;
      await firebase.auth().signOut();
      await anonymousUser.delete();
      const userCredential = await firebase.auth().signInWithCredential(error.credential);
      await UserUtils.customParseLogin(userCredential.user);
    },
    signInSuccessWithAuthResult: function(authResult, redirectUrl) {
      return false;
    },
  }
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    UiModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    FirebaseUIModule.forRoot(firebaseUiAuthConfig),
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    AkitaNgRouterStoreModule
  ],
  providers: [{ provide: NG_ENTITY_SERVICE_CONFIG, useValue: { baseUrl: 'https://jsonplaceholder.typicode.com' }}],
  bootstrap: [AppComponent]
})
export class AppModule { }
