import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'scanshop-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {

  // State for dropzone CSS toggling
  isHovering: boolean;
  isUploading: boolean;

  @Output() startUpload = new EventEmitter<File>();

  constructor() { }

  ngOnInit(): void {
  }

  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  async processFile(event: FileList): Promise<File> {
    // The File object
    const file = event.item(0)

    // Client-side validation example
    if (file.type.split('/')[0] !== 'image') {
      console.error('unsupported file type :( ')
      return;
    }

    this.isUploading = true;
    this.startUpload.emit(file);
  }

}
