import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { firebase } from 'firebaseui-angular';
import { AuthData } from 'parse';

@Injectable({
  providedIn: 'root'
})
export class UserUtils {

  static async customParseLogin(user: firebase.default.User) {
    // NOTE(stepanic): this logOut makes issues for fetching data on refresh
    // console.log('customParseLogin.before.logOut')
    // await Parse.User.logOut();
    // console.log('customParseLogin.after.logOut')
    if (user) {
      const authData: AuthData = { firebase: { id: null, access_token: null } };
      authData.firebase.id = user.uid;
      authData.firebase.access_token = await user.getIdToken();

      const response = await fetch(Parse.serverURL + '/users', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-parse-application-id': Parse.applicationId
        },
        body: JSON.stringify({authData})
      });
      const parseUser = await response.json();

      await Parse.User.become(parseUser.sessionToken);
    }
  }

}
